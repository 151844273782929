import React from 'react';
import styled from 'styled-components';

export const MultiLine1 = ({ theme = "dark", texts = [
  <p>Our recipe is roughly the planning stage of a project, it’s our pride and breaks down into several phases. It starts with a kick-off meeting.</p>, <p>Mipe is roughly the planning stagnt ingredients that make o e and breaks down into several phases. It starts with a kick-off.</p>, <p>There are different ingredients that make our personal recipe reliable, we take pride in our communication-based approach.</p>, <p>Our recipe is roughly the planning stage of a project, it’s our pride and breaks down into several phases. It starts with a kick-off meeting.</p>, <p>Mipe is roughly the planning stagnt ingredients that make o e and breaks down into several phases. It starts with a kick-off.</p>], ...moreProps }) => {
  return (
    <ML1Box theme={theme} {...moreProps}>
      {
        texts.map((item, index) =>
          <div className="ml1-line">{item}</div>)
      }
    </ML1Box>
  );
}

const ML1Box = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  font-size: calc(13px + 0.3vw);
  color: ${props => props.theme === "dark" ? "#ffffffc8" : (props.theme === "medium" ? "#ffffffd0" : "#000000b8")};
  .ml1-line {
    position: relative;
    width: 100%;
    padding: calc(20px + 0.7vw) calc(20px + 2.5vw);
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  &>div:nth-child(2n+1) {background-color: ${props => props.theme === "dark" ? "#00000050" : (props.theme === "medium" ? "#00000030" : "#00000010")};}
  &>div:nth-child(2n) {background-color: ${props => props.theme === "dark" ? "#000000f0" : (props.theme === "medium" ? "#00000048" : "#00000020")};}
`;

export const MultiLine0 = ({ theme = "dark", texts = [
  <p>Our recipe is roughly the planning stage of a project, it’s our pride and breaks down into several phases. It starts with a kick-off meeting.</p>, <p style={{ width: "85%" }}>Mipe is roughly the planning stagnt ingredients that make o e and breaks down into several phases. It starts with a kick-off.</p>, <p>There are different ingredients that make our personal recipe reliable, we take pride in our communication-based approach.</p>, <p>Our recipe is roughly the planning stage of a project, it’s our pride and breaks down into several phases. It starts with a kick-off meeting.</p>, <p>Mipe is roughly the planning stagnt ingredients that make o e and breaks down into several phases. It starts with a kick-off.</p>, <IconSnippet0 icon="fas fa-bolt" />, <IconSnippet0 iconFirst={false} />
], ...moreProps }) => {
  return (
    <ML0Box theme={theme} {...moreProps}>
      {
        texts.map((item, index) =>
          <div className="ml0-line">{item}</div>)
      }
    </ML0Box>
  );
}

const ML0Box = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  font-size: calc(13px + 0.3vw);
  color: ${props => props.theme === "dark" ? "#ffffffc8" : (props.theme === "medium" ? "#ffffffd0" : "#000000b8")};
  .ml0-line {
    position: relative;
    width: 100%;
    padding: calc(20px + 0.7vw) calc(20px + 2.5vw);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  &>div:nth-child(2n+1) {
    text-align: end;
    background-color: ${props => props.theme === "dark" ? "#00000050" : (props.theme === "medium" ? "#00000030" : "#00000010")};
    align-self: flex-end;
    /* padding: calc(30px + 1vw) calc(20px + 2.5vw) calc(30px + 1vw) calc(2 * (10px + 4.5vw)); */
  }
  &>div:nth-child(2n) {
    text-align: start;
    background-color: ${props => props.theme === "dark" ? "#000000f0" : (props.theme === "medium" ? "#00000048" : "#00000020")};
    align-self: flex-start;
    /* padding: calc(30px + 1vw) calc(2 * (10px + 4.5vw)) calc(30px + 1vw) calc(20px + 2.5vw); */
  }
`;

export const IconSnippet0 = ({ color = "#ffbb33", iconFirst = true, icon = "fas fa-clock", text = "Our recipe is roughly the planning stage of a project, it’s our pride and breaks down into several phases. It starts with a kick-off meeting." }) => {
  return (
    <IS0Box color={color} iconFirst={iconFirst}>
      <i className={icon} />
      <p>{text}</p>
    </IS0Box>
  );
}

const IS0Box = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  i {
    color: ${props => props.color + "e0"};
    position: relative;
    order: ${props => props.iconFirst ? "0" : "2"};
    opacity: 0.85;
    font-size: calc(30px + 1.5vw);
    margin: ${props => props.iconFirst ? "0 calc(20px + 1vw) 0 0" : "0 0 0 calc(20px + 1vw)"};
  }
  p {position: relative; /* text-align: center; */  order: 1; font-size: calc(13px + 0.3vw);}
  /* @media only screen and (max-width: 600px) {
    flex-direction: column;
  } */
`;
