import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import smoothscroll from 'smoothscroll-polyfill';
import { BrowserRouter, useLocation } from "react-router-dom";

smoothscroll.polyfill();
function ScrollToTop() {
  const { hash, pathname } = useLocation();
  useEffect(() => {
    if (hash && hash !== "") { return; }
    window.scrollTo(0, 0);
  }, [pathname]);// , hash ]);
  return null;
}
ReactDOM.render(<BrowserRouter><ScrollToTop /><App /></BrowserRouter>, document.getElementById('app-root'));
serviceWorker.unregister();
