import React, { useState } from 'react';
import styled from 'styled-components';
/* import imgDesign from 'img/imgs0/design.jpg'; */
import imgDesign from 'img/imgs00/design-tiny.jpg';
/* import imgDevelopment from 'img/imgs0/development.jpg';
import imgMarketing from 'img/imgs0/marketing.jpg'; */
import imgDevelopment from 'img/imgs00/development-tiny.jpg';
import imgMarketing from 'img/imgs00/digital-marketing-tiny.jpg';
import { TitleImg0 } from 'widgets/TitleImgs';
import { MultiSnippet3, MultiSnippet4 } from "widgets/MultiSnippet";
import { TextBlock1 } from "widgets/TextBlocks";
import { HashLink } from "widgets/MyHashLink";
import WebsiteTypes from "widgets/WebsiteTypes";
import { Headline0 } from 'widgets/Headlines';

const SRBox = styled.div`
  position: relative;
  width: 100%;
  padding: ${props => props.expanded === true ? "0 0 calc(20px + 1vw) 0" : "0"};
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  overflow: hidden;
  transition: all 0.3s;
  opacity:  ${props => props.expanded === true ? "1.0" : "0.0"};
  max-height: ${props => props.expanded === true ? "10000px" : "0px"};
`;

const SectionRecipes = () => {
  const [expanded, setExpanded] = useState(true);
  const toggle = () => { setExpanded(e => !e); }
  return (
    <>
      <Headline0 onClick={toggle} color={"#ffbb33"} theme={"dark"}>RECIPES<i className={expanded === true ? "fas fa-minus" : "fas fa-plus"} /></Headline0>
      <SRBox expanded={expanded}>
        <TextBlock1 style={{ marginTop: "calc(30px + 1vw)" }}><p>Our range of recipes spans the classic spectrum of products and services centered around some type of web platform: from <strong>graphic design</strong> of logos and complete responsive layouts, to setup and <strong>programming</strong>, to outreach via <strong>digital marketing</strong> solutions. Continue to explore such products, or jump to the first stage of our workflow, <HashLink smooth to="/#ingredients">ingredients</HashLink>.{/* From design blueprinting, to digital marketing: tell us about your goals and ideas, our job is to create a custom solution you can be proud of. Explore our range of digital services. */}</p><div className="jjump" /></TextBlock1>
        <TitleImg0 sectionId="recipes-design" theme="dark" color={"#77ccbb"} titleYPercent={"35%"} src={imgDesign} title={"DESIGN"} />
        <TextBlock1><div className="jump" /><p>Rely on our design experience for visual projects: from simple <strong>logo</strong> and ads design and optimization, to complex, smoothly responsive <strong>layouts</strong> of web and mobile apps.</p></TextBlock1>
        <MultiSnippet3 columnThreshold="750px" theme="dark" color="#77ccbb" titles={[
          "LOGO AND COMPONENTS", "RESPONSIVE LAYOUT", "OPTIMIZATION & SVG",
        ]} icons={[
          "fas fa-drafting-compass", "fas fa-pager", "fas fa-shapes",
        ]} texts={[
          "From logo design, to complex hero carousels and advertising banner visual optimization",
          "Smooth and responsive layout design for websites and mobile apps",
          "Visual tweaking, file optimization and vector graphics integration"
        ]} />
        <TitleImg0 sectionId="recipes-develop" theme="dark" color={"#ffbb33"} titleYPercent={"50%"} src={imgDevelopment} title={"DEVELOPMENT"} />
        <TextBlock1><div className="jump" /><p>Website development is our core service: different tools and techniques are employed for websites of various kinds, which differ in terms of interaction level (e.g. static content, dynamic, interactive), editing flexibility, constraints on part of the client, and so on. From online photo portfolios, to ecommerce platforms, to progressive mobile apps: we will carry out your goals with up-to-date <HashLink smooth to="/#tools">tools</HashLink> and <strong>full commitment</strong>.</p><div className="jump" /></TextBlock1>
        <WebsiteTypes />
        <TextBlock1><div className="jump" /><p>The tools and technologies employed also depend of whether the content is pretty much fixed, or dynamic or user-generated. There are several factors to consider when picking the right tools.</p></TextBlock1>
        <MultiSnippet3 columnThreshold="750px" theme="dark" color="#ffbb33" titles={[
          "STATIC", "DYNAMIC", "INTERACTIVE",
        ]} icons={[
          "fas fa-copy", "fas fa-user-edit", "fas fa-users",
        ]} texts={[
          "Content is essentially fixed or seldom updated (e.g. brochure website)",
          "Content is frequently updated, usually at the hand of the client (e.g. blog)",
          "Content is largely created and edited by users or a community of some sort"
        ]} />
        <TitleImg0 sectionId="recipes-divulge" theme="dark" color={"#77ccbb"} titleYPercent={"70%"} src={imgMarketing} title={"MARKETING"} />
        <TextBlock1><div className="jump" /><p>Jump with us on the digital marketing train while it provides beneficial and cost effective solutions. Together we can tailor a flexible package that makes use of e.g. <strong>social media ad campaigns</strong>, <strong>AdWords</strong> analysis, long-term <strong>content strategy</strong> and more.</p></TextBlock1>
        <MultiSnippet4 style={{ marginTop: "calc(24px + 1.6vw)" }} /* columnThreshold="750px" */ theme="dark" color="#77ccbb" titles={[
          "SEO & SEM", "LOCAL SEO", "SOCIAL MEDIA", "CONTENT MARKETING",
        ]} icons={[
          "fas fa-search", "fas fa-store", "fas fa-share-alt", "fas fa-file-alt",
        ]} texts={[
          "Search engine optimization and marketing, including Google Ads campaigns",
          "Localized SEO and Google My Business renovation for brick and mortar businesses",
          "Social media marketing, from simple ads to long-term branding strategy",
          "Content marketing, based on keyword research and competitor analysis"
        ]} />
      </SRBox>
    </>
  );
}

export default SectionRecipes;
