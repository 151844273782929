import React, { useState } from 'react';
import styled from 'styled-components';
import { TitleImg0 } from 'widgets/TitleImgs';
import { MultiLine1 } from "widgets/MultiLines";
import { MultiSnippet4 } from "widgets/MultiSnippet";
import { TextBlock1 } from "widgets/TextBlocks";
import { HashLink } from "widgets/MyHashLink";
import { Headline0 } from 'widgets/Headlines';
/* import imgSynthesis from 'img/imgs0/synthesis.jpg';
import imgWireframing from 'img/imgs0/wireframing.jpg';
import imgAdditivesRubik from 'img/imgs0/additives-rubik.jpg'; */
import imgSynthesis from 'img/imgs00/synthesis-tiny.jpg';
import imgWireframing from 'img/imgs00/wireframing-tiny.jpg';
import imgAdditivesRubik from 'img/imgs00/followup-tiny.jpg';
import FollowUpTypes from "widgets/FollowUps";

const SBBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  overflow: hidden;
  transition: all 0.3s;
  opacity:  ${props => props.expanded === true ? "1.0" : "0.0"};
  max-height: ${props => props.expanded === true ? "10000px" : "0px"};
`;

const SectionBaking = () => {
  /* const [expanded, setExpanded] = useState(true); */
  const [expanded, setExpanded] = useState(false);
  const toggle = () => { setExpanded(e => !e); }
  return (
    <>
      <Headline0 onClick={toggle} color={"#ffbb33"} theme={"medium"}>BAKING<i className={expanded === true ? "fas fa-minus" : "fas fa-plus"} /></Headline0>
      <SBBox expanded={expanded}>
        <TextBlock1 style={{ marginTop: "calc(30px + 1vw)" }} accentColor="#ffbb33" theme="medium"><p>Once the planning is laid out, the development process begins. It basically entails <strong>content shynthesis</strong> on the part of the client part and <strong>wireframing duties</strong> on our part. The client freely chooses the level of ownership over content drafting and optimization, whereas the developers modulate the wireframing part based on the provided content, and jump in to aid content work when required. To instantiate the correct loop of <strong>progressive prototype enhancement</strong>, the right balance of synergy and delegation must be first negotiated. </p><div className="jump" /></TextBlock1>
        <TitleImg0 theme="medium" color={"#ffbb33"} titleYPercent={"60%"} src={imgSynthesis} title={"SYNTHESIS"} />
        <TextBlock1 accentColor="#ffbb33" theme="medium"><div className="jump" /><p>Content has lately become key to any SEO-friendly strategy. It can certainly be improved upon over time, but polished imagery and textual assets have to be sought from the start, taking into account different sources of reference material.</p><div className="jump" /></TextBlock1>
        <MultiLine1 style={{ margin: "calc(20px + 1vw) 0" }} theme={"medium"} texts={[
          <TextBlock1 paramWidth="100%"><p>Existing imagery and written content has to be polished, style-corrected and integrated into the project.</p></TextBlock1>,
          <TextBlock1 paramWidth="100%"><p style={{ position: 'relative' }}>Keeping a reference to content across comparable websites as indication about industry standards, see Stand Out <HashLink smooth to="/#standout">section</HashLink>.</p></TextBlock1>,
          <TextBlock1 paramWidth="100%"><p>Manufacturing of new textual content and up-to-date photo and photoediting media assets.</p></TextBlock1>]} />
        <TitleImg0 theme="medium" color={"#389482"} titleYPercent={"35%"} src={imgWireframing} title={"WIREFRAMING"} />
        <TextBlock1 accentColor="#ffbb33" theme="medium"><div className="jump" /><p>While content is put together on one side, the global layout is sketched on the other. As content flows in, more and more educated and definitive decisions can be made in terms of visual presentation as well.</p></TextBlock1>
        <MultiSnippet4 theme="medium" color="#90D5C7" titles={[
          "CONTENT OPTIMIZATION", "CUSTOM COMPONENTS", "VISUAL CHARACTERIZATION", "NAVIGATION BLUEPRINT"
        ]} icons={[
          "fas fa-keyboard", "fas fa-screwdriver", "fas fa-palette", "fas fa-sitemap"
        ]} texts={[
          "sit amet, consectetur adipiscing elit. Donec id finibus tellus, at finibus sem. Morbi eleifend malesuada nunc.",
          "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt",
          "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt",
          "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada."
        ]} />
        <TitleImg0 theme="medium" color={"#ffbb33"} titleYPercent={"50%"} src={imgAdditivesRubik} title={"FOLLOW-UP"} />
        <TextBlock1 accentColor="#ffbb33" theme="medium"><div className="jjump" /><p>Once the prototype is polished and bugfree to the desired degree, it can be hosted and shared for a live test stage before the open launch. However, the end of development is just the commencement of a website and the tasks it brings about: additional content, statistical analysis, CMS integration and digital marketing are just some of the subsequent steps one might have to handle.</p></TextBlock1>
        <FollowUpTypes />
      </SBBox>
    </>
  );
}

export default SectionBaking;
