import React from 'react';
import styled from 'styled-components';
import { theme0 } from 'data/Config';
import logo512 from 'img/logo0-512.png';

const Nav2 = ({ shrinked = false, menuOpen, menuToggleCallback, vanishingAtHomeTop = false }) => {
  return (
    <NavBox vanishingAtHomeTop={vanishingAtHomeTop} shrinked={shrinked}>
      <img src={logo512} alt="Bitscuits development" />
      <NavTitle shrinked={shrinked}>
        <h1>BITSCUITS</h1>
        <h2>DESIGN DEVELOP DIVULGE</h2>
      </NavTitle>
      <NavMenu menuOpen={menuOpen} onClick={menuToggleCallback} shrinked={shrinked} className="fas fa-bars" />
    </NavBox>
  );
}

const NavMenu = styled.i`
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  font-size: ${props => props.shrinked === true ? "40px" : "calc(28px + 1.8vw)"};
  /* color: #77ccbb; */
  color: #ffbb33;
  margin: 0 calc(6px + 0.6vw);
  padding: 0 calc(3px + 0.2vw);
  transform: ${props => (props.menuOpen === true) ? "scale(1.0) rotateZ(90deg)" : "scale(1.0) rotateZ(0deg)"};
  @media(hover: hover) and (pointer: fine) {
    &:hover {
      transform: ${props => (props.menuOpen === true) ? "scale(1.2) rotateZ(90deg)" : "scale(1.2) rotateZ(0deg)"};
    }
  }
  @media only screen and (max-width: 330px) {
    font-size: ${props => props.shrinked === true ? "30px" : "calc(26px + 1.2vw)"};
  }
`;

const NavTitle = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 calc(10px + 0.8vw);
  h1 {
    position: relative;
    transition: all 0.3s;
    font-size: ${props => props.shrinked === true ? "40px" : "calc(30px + 3vw)"};
    line-height: 0.8;
    letter-spacing: calc(0.2vw);
    font-weight: 600;
    /* color: #d0d0d0; */
    color: #e8e8e8;
    &::first-letter {color: #FFBB33; }
  }
  h2 {
    display: ${props => props.shrinked === true ? "none" : "initial"};
    position: relative;
    transition: all 0.3s;
    font-size: ${props => props.shrinked === true ? "calc(11px + 0.95vw)" : "calc(11px + 1.35vw)"};
    line-height: 0.8;
    font-weight: 500;
    color: #c0c0c0;
  }
  @media only screen and (max-width: 400px) {
    h1 {
      position: relative;
      /* font-size: ${props => props.shrinked === true ? "calc(15px + 1.5vw)" : "calc(20px + 5vw)"}; */
      letter-spacing: 1px;
      font-weight: 600;
      /* color: #505050; */
      color: #e8e8e8;
      &::first-letter {color: unset; }
      margin: 0;
    }
  }
  @media only screen and (max-width: 330px) {
    h1 {
      font-size: ${props => props.shrinked === true ? "30px" : "calc(24px + 2.4vw)"};
    }
    h2 {
      font-size: ${props => props.shrinked === true ? "calc(10px + 0.8vw)" : "calc(10px + 1vw)"};
    }
  }
  @media only screen and (min-width: 1441px) {
    h1 {
      font-size: ${props => props.shrinked === true ? "36px" : "75px"};
      letter-spacing: 4px;
    }
    h2 {
      font-size: ${props => props.shrinked === true ? "24px" : "30px"};
      letter-spacing: 1px;
    }
  }
`;

const NavBox = styled.aside`
  position: relative;
  width: 100%;
  height: ${props => props.vanishingAtHomeTop === true ? "0px" : (props.shrinked === true ? "96px" : theme0.navFatHeight)};
  overflow: hidden;
  opacity: ${props => props.vanishingAtHomeTop === true ? "0.0" : "1.0"};
  background-color: ${theme0.colorNavBackground};
  box-shadow: 0px 3px 6px #000000b0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  img {
    position: relative;
    transition: all 0.3s;
    height: ${props => props.shrinked === true ? "60px" : theme0.navFatLogoHeight};
    width: ${props => props.shrinked === true ? "60px" : theme0.navFatLogoHeight};
    margin: 0 calc(6px + 0.6vw);
  }
  @media only screen and (max-width: 400px) {
    img {
      position: absolute;
      top: 8px;
      left: 8px;
      height: calc(100% - 16px);
      width: auto;
      opacity: 0.25;
    }
  }
  /* @media only screen and (min-width: 1441px) {} */
`;

export default Nav2;
