import React from 'react';
import styled from 'styled-components';
import { theme0 } from "data/Config";

export const Headline0 = styled.h2`
i {
  position: absolute;
  font-size: calc(20px + 0.67vw);
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${props => props.color + "90"};
}
cursor: pointer;
position: relative;
width: 100%;
user-select: none;
/* margin: 0 0 calc(30px + 1vw) 0; */
padding: calc(30px + 1.5vw) calc(30px + 1.5vw) /* calc(80px + 4vw) *//* 0 */;
letter-spacing: calc(5px + 0.7vw);
line-height: 1.25;
font-size: calc(30px + 3vw);
@media only screen and (max-width: 500px) {
  letter-spacing: calc(4px + 0.4vw);
  font-size: calc(28px + 2vw);
}
@media only screen and (max-width: 400px) {
  letter-spacing: calc(4px + 0.4vw);
  font-size: calc(26px + 1.8vw);
}
@media only screen and (max-width: 330px) {
  letter-spacing: calc(4px + 0.4vw);
  font-size: calc(24px + 1vw);
}
/* @media only screen and (max-width: 600px) {
  font-size: calc(30px + 2vw);
}

@media only screen and (max-width: 400px) {
  font-size: calc(20px + 2vw);
} */
font-weight: 600;
text-transform: uppercase;
/* color: ${theme0.colorAccentOrange + "ff"}; */
color: ${props => (props.theme === "dark") ? "#ffffffc0" : ((props.theme === "medium") ? "#ffffffd8" : "#00000060")};
/* text-align: ${props => props.align = "left" ? "start" : "end"}; */
text-align: center;
box-shadow: ${props => (props.theme === "dark") ? "0px 3px 12px #000000ff" : ((props.theme === "medium") ? "0px 3px 12px #000000b0" : "0px 3px 12px #00000080")};
/* @media only screen and (max-width: 768px) {
  text-align: center;
} */
&::first-letter { color: ${props => props.color}; /* font-size: calc(40px + 2vw); font-weight: 500; */ }
`;
