import React from 'react';
import styled from 'styled-components';
import { theme0 } from "data/Config";
import { MultiSnippet3, MultiSnippet4, MultiSnippetCollapsible3, MultiSnippetCollapsible4 } from "widgets/MultiSnippet";

const Home = () => {
  return (
    <HomeBox>
      <HomeSection sectionId="products-services" title="COOKIES" subtitle="products and services" titleAlign="left">
        {/* <MultiSnippet3NoText color={"#30a2ff"} titles={[
          "LOL ECCE", "PORPOSYCHY", "ECCE HOMO", "ECCE HOMO"
        ]} icons={[
          "fas fa-clock", "fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"
        ]} /> */}
        <MultiSnippet3 titles={[
          "LOL ECCE", "PORPOSYCHY", "ECCE HOMO", "ECCE HOMO"
        ]} icons={[
          "fas fa-clock", "fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"
        ]} texts={[
          "sit amet, consectetur adipiscing elit. Donec id finibus tellus, at finibus sem. Morbi eleifend malesuada nunc.",
          "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt",
          "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt",
          "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada."
        ]} />
        <MultiSnippetCollapsible3 synchronize={false} titles={[
          "LOL ECCE", "PORPOSYCHY", "ECCE HOMO", "ECCE HOMO"
        ]} icons={[
          "fas fa-clock", "fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"
        ]} texts={[
          "sit amet, consectetur adipiscing elit. Donec id finibus tellus, at finibus sem. Morbi eleifend malesuada nunc.",
          "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt",
          "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt",
          "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada."
        ]} />
        <MultiSnippet4 color={theme0.colorAccent} titles={[
          "LOL ECCE", "DESIGN E SVILUPPO DI APPLICAZIONI", "ECCE HOMO", "ECCE HOMO"
        ]} icons={[
          "fas fa-clock", "fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"
        ]} texts={[
          "sit amet, consectetur adipiscing elit. Donec id finibus tellus, at finibus sem. Morbi eleifend malesuada nunc.",
          "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt",
          "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt",
          "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada."
        ]} />
        <MultiSnippetCollapsible4 color={theme0.colorAccent} titles={[
          "LOL ECCE", "DESIGN E SVILUPPO DI APPLICAZIONI", "ECCE HOMO", "ECCE HOMO"
        ]} icons={[
          "fas fa-clock", "fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"
        ]} texts={[
          "sit amet, consectetur adipiscing elit. Donec id finibus tellus, at finibus sem. Morbi eleifend malesuada nunc.",
          "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt",
          "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt",
          "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada."
        ]} />
      </HomeSection>

      <HomeSection sectionId="workflow" title="BAKING" subtitle="workflow step by step" titleAlign="right">

      </HomeSection>

      <HomeSection sectionId="project-planning" title="RECIPE" subtitle="our planning frame" titleAlign="center">

      </HomeSection>

      <HomeSection sectionId="skills" title="INGREDIENTS" subtitle="what we bring to the table" titleAlign="left">

      </HomeSection>

      <HomeSection sectionId="background" title="COOKS" subtitle="background values" titleAlign="right">

      </HomeSection>

      <HomeSection sectionId="contacts" title="CONTACTS" subtitle="get in touch!" titleAlign="center">

      </HomeSection>
    </HomeBox>
  );
}

const HomeBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const HomeSection = ({ sectionId = "section-id", title = "SECTION Headline", subtitle = "section SUBTITLE", titleAlign = "left", children = <><br /><br /><br /><br /><br /><br /></>, ...moreProps }) => {
  return (
    <HomeSectionBox {...moreProps}>
      <HomeHashTarget id={sectionId} />
      <HomeSectionTitle align={titleAlign}>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </HomeSectionTitle>
      {children}
    </HomeSectionBox>
  );
}

const HomeSectionBox = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const HomeSectionTitle = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  /* margin: 0 calc(6px + 0.6vw); */
  padding: calc(40px + 2vw) calc(20px + 1vw);
  box-shadow: 3px 3px 6px #000000b0;
  h1 {
    position: relative;
    transition: all 0.3s;
    font-size: ${props => props.shrinked === true ? "calc(30px + 2vw)" : "calc(30px + 3vw)"};
    line-height: 0.8;
    letter-spacing: calc(0.2vw);
    font-weight: 600;
    color: #d0d0d0;
    &::first-letter {color: #FFBB33; }
    margin-right: calc(60px + 6vw);
  }
  h2 {
    position: relative;
    transition: all 0.3s;
    font-size: ${props => props.shrinked === true ? "calc(11px + 0.95vw)" : "calc(11px + 1.35vw)"};
    line-height: 0.8;
    font-weight: 600;
    /* color: #a0a0a0; */
    color: #77ccbb78;
    text-transform: uppercase;
    margin-left: calc(30px + 3vw);
  }
  @media only screen and (max-width: 400px) {
    h1 {
      position: relative;
      font-size: ${props => props.shrinked === true ? "calc(20px + 4vw)" : "calc(20px + 5vw)"};
      letter-spacing: 1px;
      font-weight: 600;
      color: #505050;
      &::first-letter {color: unset; }
      margin: 0;
    }
  }
  @media only screen and (min-width: 1441px) {
    h1 {
      font-size: ${props => props.shrinked === true ? "60px" : "75px"};
      letter-spacing: 4px;
    }
    h2 {
      font-size: ${props => props.shrinked === true ? "24px" : "30px"};
      letter-spacing: 1px;
    }
  }
`;

const HomeHashTarget = styled.div`
  position: absolute;
  top: ${" -" + theme0.navHeight};
  left: 0px;
  width: 0px;
  height: 0px;
`;

export default Home;
