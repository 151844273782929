import React from 'react';
import styled from 'styled-components';
import { TitleImg0 } from 'widgets/TitleImgs';
import { IconSnippet0, MultiLine0 } from "widgets/MultiLines";
import { MultiSnippet3 } from "widgets/MultiSnippet";
import { TextBlock1 } from "widgets/TextBlocks";
import imgCooks from 'img/imgs00/the-cooks-tiny.jpg';
import imgContacts from 'img/imgs00/contacts-tiny.jpg';
import Tools from "widgets/Tools";

const SectionCooks = () => {
  return (
    <>
      <SectionShadow />
      <TitleImg0 theme="dark" color={"#ffbb33"} titleYPercent={"50%"} src={imgCooks} title={"THE COOKS"} />
      <TextBlock1><div className="jjump" /><p>We are a small passionate team that becomes fiercely committed to helping you <strong>reach your digital goals</strong> and present you with a <strong>tailored solution</strong> that you can take pride in. What skills do we bring to the table, and what values do we keep on it?</p><div className="jump" /></TextBlock1>
      <MultiLine0 style={{ margin: "calc(10px + 1vw) 0" }} theme={"medium"} texts={[
        <IconSnippet0 style={{ padding: "0 4vw" }} text="A friendly and open communication-based approach to quickly dissipate uncertainty and clearly outline possibilities and limitations." icon="fas fa-phone" iconFirst={false} />,
        <IconSnippet0 style={{ padding: "0 4vw" }} text="We come from totally different backgrounds, with complementary skills in the digital domain acquired through a diverse array of experiences." icon="fas fa-puzzle-piece" />,
        <IconSnippet0 style={{ padding: "0 4vw" }} text="We value first and foremost your satisfaction, especially in terms of user interface and smooth interaction, in the fulfillment of your goals." icon="fas fa-couch" iconFirst={false} />,
        <IconSnippet0 style={{ padding: "0 4vw" }} text="We are easily excited to explore interesting technologies. We keep our tools updated and optimized to deliver custom creations at a reasonable pace." icon="fas fa-sync-alt" />]} />
      <TextBlock1 sectionId="tools"><div className="jjump" /><p>Here are some of the tools and services we use for design and development</p><div className="jump" /></TextBlock1>
      <Tools style={{ margin: "calc(20px + 1vw)" }} />
      <TitleImg0 sectionId="contacts" theme="dark" color={"#77ccbb"} titleYPercent={"50%"} src={imgContacts} title={"CONTACTS"} />
      <TextBlock1><p>Contact us with no hesitation for a <strong>free consultation</strong>, any piece of information, or just a friendly chat</p><div className="jump" /></TextBlock1>
      <MultiSnippet3 style={{ maxWidth: "1000px" }} /* style={{ margin: "0" }} */ columnMarginBottom="calc(40px + 1vw)" columnThreshold="500px" theme="dark" color="#77ccbb" titles={[
        "E-MAIL", "TELEPHONE", "MEET US",
      ]} icons={[
        "fas fa-envelope", "fas fa-phone", "fas fa-map-marker-alt",
      ]} texts={[
        "mdigitalia@gmail.com",
        "+39 3338912909",
        "Milano (Italy)"
      ]} />
    </>
  );
}

const SectionShadow = styled.div`
  width: 100%;
  /* height: calc(12px + 0.7vw); */
  height: 2px;
  margin-bottom: calc(30px + 1.5vw);
  background-image: linear-gradient(to bottom, #000000c0, #00000000, #00000000);
  box-shadow: 0px 3px 5px #000000d0;
`;

export default SectionCooks;
