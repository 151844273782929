import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { useHomepage } from "utils/Utils";
import { theme0 } from 'data/Config';
import Header from 'layout/Header';
import Header2 from 'layout/Header2';
import Nav from 'layout/Nav';
import Nav2 from 'layout/Nav2';
import Menu from "layout/Menu";
import Home from "pages/Home";
import Home2 from "pages/Home2";
import imgLogo512 from 'img/logo1-512.png';
import { HashLink } from "widgets/MyHashLink";
import FABMenu from "layout/FABMenu";
import { Route, Switch } from "react-router-dom";

/*
    color: #ffbb33;
    color: #77ccbb;
z-indexes: appnav 10,
*/

function App() {
  const homepage = useHomepage();
  const [headRef, headInView,] = useInView({ threshold: 0.5 });
  const [menuOpen, setMenuOpen] = useState(false);
  const menuShow = () => setMenuOpen(true);
  const menuDismissed = () => setMenuOpen(false);
  const [appFoot, appFootInView] = useInView({ threshold: 0.75 });
  const [fabVisible, setFabVisible] = useState(false);
  useEffect(() => { setFabVisible(!headInView); }, [headInView]);
  return (
    <ThemeProvider theme={theme0}>
      <AppBox>
        <Switch>
          <Route exact path="/it" render={(props) =>
            <>
              <AppHeader ref={headRef}>
                <Header it={true} />
                {
        /* navBorn && */ <AppNav>
                    <Nav2 vanishingAtHomeTop={homepage && theme0.navVanishAtHomeTop && headInView} shrinked={homepage && !headInView}
                      menuOpen={menuOpen} menuToggleCallback={menuShow} />
                  </AppNav>
                }
              </AppHeader>
            </>
          } />
          <Route render={(props) =>
            <>
              <AppHeader ref={headRef}>
                <Header2 />
                {
            /* navBorn && */ <AppNav>
                    <Nav2 vanishingAtHomeTop={homepage && theme0.navVanishAtHomeTop && headInView} shrinked={homepage && !headInView}
                      menuOpen={menuOpen} menuToggleCallback={menuShow} />
                  </AppNav>
                }
              </AppHeader>
              <AppMain>
                <Home2 />
              </AppMain>
              <AppFooter ref={appFoot}>
                <HashLink smooth to="/#start"><img src={imgLogo512} alt={"© " + (new Date().getFullYear()) + " Bitscuits - Milano (Italy)"} /></HashLink>
                <div>
                  <p className="footer-copy">{"© " + (new Date().getFullYear()) + " Bitscuits - Milano (Italy)"}</p>
                  <p className="footer-email">{"mdigitalia@gmail.com"}</p>
                  <p className="footer-tel">{"+39 3338912909"}</p>
                </div>
              </AppFooter>
              {fabVisible && <FABMenu fabUp={appFootInView} />}
              {menuOpen && <Menu onDismiss={menuDismissed} />}
            </>
          } />
        </Switch>
      </AppBox>
    </ThemeProvider>
  );
}

const AppBox = styled.div`
  position: relative;
  width: 100%;
  /* min-height: 100vh; */
  margin: auto;

  color: #ffffff;
  background-color: #000000;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  @media only screen and (min-width: ${theme0.appMinOverWidth}) {
    max-width: ${theme0.appMaxWidth};
    box-shadow: 4px 0px 6px #000000b0;
  }
`;

const AppHeader = styled.header`
  position: relative;
  width: 100%;
  /* height: 100vh;
  max-height: 800px; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const AppMain = styled.main`
  position: relative;
  width: 100%;
  /* min-height: ${"calc(100vh - " + theme0.footerHeight + ")"};
  @media only screen and (max-width: ${theme0.footerFatMaxWidth}) {min-height: ${"calc(100vh - " + theme0.footerFatHeight + ")"};} */

  /* background-color: #ffffff; */

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const AppFooter = styled.footer`
  position: relative;
  width: 100%;
  height: ${theme0.footerHeight};
  /* @media only screen and (max-width: ${theme0.footerFatMaxWidth}) {
    height: ${theme0.footerFatHeight};
    &>img {
      height: ${"calc(0.75 * (" + theme0.footerFatHeight + "))"};
    }
  } */

  background-color: #000000;
  /* background-color: ${theme0.colorDarkerDark}; */

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  &>a {
    position: relative;
    margin-right: calc(12px + 0.4vw);
    height: ${"calc(0.65 * (" + theme0.footerHeight + "))"};
    opacity: 0.75;
    transition: all 0.25s;
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    &>img {
      position: relative;
      height: 100%;
      width: auto;
    }
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        opacity: 1.0;
      }
    }
  }
  &>div {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    font-size: calc(12px + 0.2vw);
    .footer-copy {
      color: ${theme0.colorOffWhite + "d0"};
    }
    .footer-email {
      color: ${theme0.colorOffWhite + "a0"};
    }
    .footer-tel {
      color: ${theme0.colorOffWhite + "a0"};
    }
  }
  transition: all 0.3s;
`;

const AppNav = styled.nav`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  transition: all 0.3s;
  @media only screen and (min-width: ${theme0.appMinOverWidth}) {
    width: ${theme0.appMaxWidth};
    left: 50%;
    transform: translateX(-50%);
  }
`;

export default App;
