import React, { useState } from 'react';
import styled from 'styled-components';

export const BlueRippleButton = ({ text = "Compra online", icon = "fas fa-shopping-cart" }) => <RippleButton effect="invert" rippleSize={2.5} rippleColor={"#ffffffb0"} border={"2px"} color={"#0099cc"} bgcolor={"#ffffff"} text={text} iconFAClass={icon} />

export const GreenRippleButton = ({ text = "Compra online", icon = "fas fa-shopping-cart" }) => <RippleButton effect="invert" rippleSize={2.5} rippleColor={"#ffffffb0"} border={"2px"} color={"#25D266"} bgcolor={"#ffffff"} text={text} iconFAClass={icon} />

/* export const BlueInvertRippleButtonSumbit = ({ text = "Compra online", icon = "fas fa-shopping-cart", click, ...moreProps }) => <RippleSumbitButton rippleSize={2.5} rippleColor={"#30a2ff80"} border={"0px"} color={"#ffffff"} bgcolor={"#30a2ff"} text={text} iconFAClass={icon} onMyClick={click} {...moreProps} /> */

export const BlueInvertRippleButton = ({ text = "Compra online", icon = "fas fa-shopping-cart", click, ...moreProps }) => <RippleButton effect="invert" rippleSize={2.5} rippleColor={"#25595180"} border={"0px"} color={"#DAF1EC"} bgcolor={"#255951"} text={text} iconFAClass={icon} onMyClick={click} {...moreProps} />

export const GreenInvertRippleButton = ({ text = "Compra online", icon = "fas fa-shopping-cart", click, ...moreProps }) => <RippleButton effect="invert" rippleSize={2.5} rippleColor={"#25D26680"} border={"0px"} color={"#ffffff"} bgcolor={"#25D266"} text={text} iconFAClass={icon} onMyClick={click} {...moreProps} />

export const BlueInvertCircleRippleButton = ({ text = "", icon = "fas fa-shopping-cart", click, ...moreProps }) => <RippleButton effect="circle-invert" rippleSize={2} rippleColor={"#0099cc80"} border={"0px"} color={"#ffffff"} bgcolor={"#0099cc"} text={text} iconFAClass={icon} onMyClick={click} {...moreProps} />

export const GreenInvertCircleRippleButton = ({ text = "", icon = "fas fa-shopping-cart", click, ...moreProps }) => <RippleButton effect="circle-invert" rippleSize={2} rippleColor={"#25D26680"} border={"0px"} color={"#ffffff"} bgcolor={"#25D266"} text={text} iconFAClass={icon} onMyClick={click} {...moreProps} />

export const BlueInvertRippleButtonOutline = ({ text = "COMPRA", icon = "fas fa-shopping-cart", click, ...moreProps }) => <RippleButton effect="invert" rippleSize={2.5} color={"#0099cc"} bgcolor={"#ffffff"} rippleColor={"#ffffffb0"} shadow={false} border={"2px"} text={text} iconFAClass={icon} onMyClick={click} {...moreProps} />

export const GreenInvertRippleButtonOutline = ({ text = "COMPRA", icon = "fas fa-shopping-cart", click, ...moreProps }) => <RippleButton effect="invert" rippleSize={2.5} color={"#25D266"} bgcolor={"#ffffff"} rippleColor={"#ffffffb0"} shadow={false} border={"2px"} text={text} iconFAClass={icon} onMyClick={click} {...moreProps} />

const RippleButtonBox = styled.div`
  user-select: none;
  position: relative;
  width: auto;
  height: calc(50px + 1vw);
  padding: calc(12px + 0.5vw) calc(30px + 1vw);
  background-color: ${props => (props.bgcolor && props.bgcolor !== "") ? props.bgcolor : "#ffb300"};
  /* background-image: linear-gradient(135deg, #77ccbb, #FFBB33); */
  color: ${props => (props.color && props.color !== "") ? props.color : "#5E2D00"};
  border: ${props => (props.border && props.border !== "") ? (props.border + " solid " + props.color) : "none"};
  /* border-radius: calc(20px + 0.4vw); */
  border-radius: 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: calc(16px + 0.4vw);
  will-change: transform;
  overflow: hidden;
  p {margin-right: 6px;}
  i {margin-left: 6px;}
  transition: all 0.3s;
  cursor: pointer;
  box-shadow: ${props => (props.shadow && props.shadow === true) ? "2px 2px 8px #000000f0" : "none"};
`;

const RippleCircleButtonBox = styled.div`
  user-select: none;
  position: relative;
  width: calc(70px + 1vw);
  height: calc(70px + 1vw);
  border-radius: calc(35px + 0.5vw);
  background-color: ${props => (props.bgcolor && props.bgcolor !== "") ? props.bgcolor : "#ffb300"};
  color: ${props => (props.color && props.color !== "") ? props.color : "#5E2D00"};
  border: ${props => (props.border && props.border !== "") ? (props.border + " solid " + props.color) : "none"};
  display: grid;
  place-items: center;
  font-weight: 500;
  font-size: calc(16px + 0.2vw);
  will-change: transform;
  overflow: hidden;
  p {margin-right: 6px;}
  i {
    font-size: ${props => "calc(" + props.iconSize + " * (32px + 0.5vw))"};
    padding: ${props => props.iconPadding};
  }
  transition: all 0.3s;
  cursor: pointer;
  box-shadow: ${props => (props.shadow && props.shadow === true) ? "4px 4px 6px #000000a0" : "none"};
`;

const RippleButtonColor = styled(RippleButtonBox)`
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        color: ${props => (props.hoverColor && props.hoverColor !== "") ? props.hoverColor : "#ffb300"};
        background-color: ${props => (props.hoverBgColor && props.hoverBgColor !== "") ? props.hoverBgColor : "#5E2D00"};
        border-color: ${props => (props.hoverColor && props.hoverColor !== "") ? props.hoverColor : "#ffb300"};
      };
    }
`;

const RippleButtonInvert = styled(RippleButtonBox)`
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        color: ${props => (props.bgcolor && props.bgcolor !== "") ? props.bgcolor : "#ffb300"};
        background-color: ${props => (props.color && props.color !== "") ? props.color : "#5E2D00"};
      };
    }
`;

const RippleCircleButtonInvert = styled(RippleCircleButtonBox)`
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        color: ${props => (props.bgcolor && props.bgcolor !== "") ? props.bgcolor : "#ffb300"};
        background-color: ${props => (props.color && props.color !== "") ? props.color : "#5E2D00"};
      };
    }
`;

const RippleCircleButtonDarken = styled(RippleCircleButtonBox)`
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        background-color: ${props => (props.darkColor && props.darkColor !== "") ? props.darkColor : "#000000"};
      };
    }
`;

const RippleButtonSwell = styled(RippleButtonBox)`
    @media(hover: hover) and (pointer: fine) {
      &:hover {transform: scale(1.1);};
    }
`;

const buttonTypeList = new Map([
  ["invert", RippleButtonInvert],
  ["color", RippleButtonColor],
  ["swell", RippleButtonSwell],
  ["circle-darken", RippleCircleButtonDarken],
  ["circle-invert", RippleCircleButtonInvert]]);

export const RippleButton = ({ text, hoverBgColor, hoverColor, iconFAClass, onMyClick, color, rippleSize = 3.0, bgcolor, border, rippleColor, shadow = true, darkColor, iconSize = 1.0, iconPadding = "0 0 0 0", effect = "invert", ...moreProps }) => {
  const [ripple, setRipple] = useState({ rippling: false, x: -5, y: -5 });

  const click = (event) => {
    onMyClick && onMyClick();
    event.stopPropagation();
  }

  const mouseDown = (event) => {
    event.stopPropagation();
    let currentTargetRect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - currentTargetRect.left;
    const y = event.clientY - currentTargetRect.top;
    setRipple({ rippling: true, x: x, y: y });
  }

  const animEnd = () => { setRipple({ rippling: false, x: -1, y: -1 }); }
  const TagName = buttonTypeList.has(effect) ? buttonTypeList.get(effect) : RippleButtonInvert;
  return (
    <TagName {...moreProps} iconPadding={iconPadding} iconSize={iconSize} darkColor={darkColor} shadow={shadow} border={border} hoverBgColor={hoverBgColor} hoverColor={hoverColor} color={color} bgcolor={bgcolor} onMouseDown={mouseDown} onClick={click}>
      {(text && text !== "") && <p>{text}</p>}
      <i className={iconFAClass} />
      {ripple.rippling && <RippleDiv color={(rippleColor && rippleColor !== "") ? rippleColor : "#00000090"} size={rippleSize} onAnimationEnd={animEnd} x={ripple.x} y={ripple.y} />}
    </TagName>
  );
}

const rippleSizePx = 100;
const rippleHalfSizePx = 50;
export const RippleDiv = styled.div`
    width: ${rippleSizePx}px;
    height: ${rippleSizePx}px;
    border-radius: 50%;
    background-color: ${props => (props.color && props.color !== "") ? props.color : "#00000040"};
    position: absolute;
    left: ${props => (props.x - rippleHalfSizePx)}px;
    top: ${props => (props.y - rippleHalfSizePx)}px;
    opacity: 0.5;
    transform: scale(0.1);
    animation: ripple-up linear 0.3s 1 forwards;
    @keyframes ripple-up {
      0% {opacity: 0.5;transform: scale(0.1);}
      75% {opacity: 1.0;${props => "transform: scale(" + props.size + ")"};}
      100% {opacity: 0.0;${props => "transform: scale(" + props.size + ")"};}
    }
`;

export default RippleButton;
