import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useKey } from 'react-use';
import ExpandableMenu from "widgets/ExpandableMenu";
import logo512 from 'img/logo0-512.png';

const Menu = ({ onDismiss }) => {
  const [dismissing, setDismissing] = useState(false);
  const dismiss = () => setDismissing(true);
  const animEnd = (e) => { if (e.animationName === "menu-go" || e.animationName === "menu-go-overwide") { onDismiss(); } }
  const clickOut = (e) => { e.stopPropagation(); dismiss(); }
  const clickIn = (e) => { e.stopPropagation(); }
  useKey("Escape", dismiss);

  const firstUpdate = useRef(true);
  const location = useLocation();
  /* TODO: compare url and hash, this quits menu even if you click the link to your very url (location key changes) */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if (firstUpdate.current === true) { firstUpdate.current = false; } else { dismiss(); } }, [location]);

  return (
    createPortal(
      <MenuOverlay onAnimationEnd={animEnd} onMouseDown={clickOut} dismissing={dismissing}>
        <MenuDialog onMouseDown={clickIn} dismissing={dismissing}>
          <img src={logo512} alt="Bitscuits" />
          <ExpandableMenu multiExpand={false} />
        </MenuDialog>
      </MenuOverlay>
      , document.getElementById("menu-root")
    )
  );
}

const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff40;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  z-index: 999;
  animation: ${props => (props.dismissing === false) ? "menu-appear 0.2s ease-out 0.0s 1 forwards" :
    "menu-vanish 0.2s ease-out 0.0s 1 forwards"};
  @keyframes menu-appear { 0% { opacity: 0.0; } 100% { opacity: 1.0; } }
  @keyframes menu-vanish { 0% { opacity: 1.0; } 100% { opacity: 0.0; } }
`;

const MenuDialog = styled.div`
  position: relative;
  width: 300px;
  @media only screen and (max-width: 460px) {
    width: 65%;
  }
  height: 100%;
  box-shadow: 3px 0px 6px #000000d0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  &>img {
    opacity: 0.75;
    width: 64px;
    max-width: 50%;
    margin: 30px 30px 20px 30px;
  }
  /* background-image: linear-gradient(to right, #265951f0, #265951ff); */
  background-image: linear-gradient(to right, #272121f0, #272121ff);
  @media only screen and (max-width: 1440px) {
    animation: ${props => (props.dismissing === false) ? "menu-come 0.2s ease-out 0.0s 1 forwards" :
    "menu-go 0.2s ease-out 0.0s 1 forwards"};
  }
  @media only screen and (min-width: 1441px) {
    width: 400px;
    animation: ${props => (props.dismissing === false) ? "menu-come-overwide 0.3s ease-out 0.0s 1 forwards" :
    "menu-go-overwide 0.2s ease-out 0.0s 1 forwards"};
  }
  @keyframes menu-come-overwide {
    0% { transform: translateX(calc(((99vw - 1440px) / 2.0) -150%)); opacity: 0.0; }
    80% { transform: translateX(calc((99vw - 1440px) / 2.0)); opacity: 0.3; }
    100% { transform: translateX(calc((99vw - 1440px) / 2.0)); opacity: 1.0; }
  }
  @keyframes menu-go-overwide {
    0% { transform: translateX(calc((99vw - 1440px) / 2.0)); opacity: 1.0; }
    100% { transform: translateX(calc(((99vw - 1440px) / 2.0) -150%)); opacity: 0.0; }
  }
  @keyframes menu-come {
    0% { transform: translateX(-150%); opacity: 0.0; }
    100% { transform: translateX(0%); opacity: 1.0; }
  }
  @keyframes menu-go {
    0% { transform: translateX(0%); opacity: 1.0; }
    100% { transform: translateX(-150%); opacity: 0.0; }
  }
`;

export default Menu;
