import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme0 } from "data/Config";
import { useLocation } from "react-router-dom";

export const SectionHashTarget = styled.div`
  position: absolute;
  top: ${" -" + theme0.navHeight};
  left: 0px;
  width: 0px;
  height: 0px;
`;

export function useHomepage() {
  const { pathname } = useLocation();
  const [isHomepage, setIsHomepage] = useState(pathname === "/");
  /* || (pathname.length > 1 && pathname[0] === "/" && pathname[1] === "#") hash should not be included in pathname */
  useEffect(() => { setIsHomepage(pathname === "/"); }, [pathname]);
  return isHomepage;
}
