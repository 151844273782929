import React from 'react';
import styled from 'styled-components';

const DoubleContent = ({ theme = "dark", childs = [<p>Some content #1</p>, <p><strong>Ssstrong</strong> content #2</p>], ...moreProps }) => {
  return (
    <DoubleContentBox {...moreProps} theme={theme}>
      <div className="db-child1">
        {childs[0]}
      </div>
      <div className="db-child2">
        {childs[1]}
      </div>
    </DoubleContentBox>
  );
}

const DoubleContentBox = styled.div`
  position: relative;
  width: 100%;
  color: ${props => props.theme === "dark" ? "#ffffffc8" : (props.theme === "medium" ? "#ffffffd0" : "#000000b8")};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  font-size: calc(13px + 0.3vw);
  &>div {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: calc(24px + 1vw);
  }
  .db-child1 {
    background-color: ${props => props.theme === "dark" ? "#00000050" : (props.theme === "medium" ? "#00000030" : "#00000010")};
  }
  .db-child2 {
    background-color: ${props => props.theme === "dark" ? "#000000f0" : (props.theme === "medium" ? "#00000048" : "#00000020")};
  }
  @media only screen and (max-width: 650px) {
    font-size: calc(13px + 0.15vw);
    &>div {
      padding: calc(24px + 1vw) calc(16px + 0.8vw);
    }
  }
  @media only screen and (max-width: 550px) {
    flex-flow: column nowrap;
    font-size: 13px;
    &>div {
      width: 100%;
      padding: calc(20px + 0.8vw);
    }
  }
`;

export default DoubleContent;
