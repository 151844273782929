import React from 'react';
import styled from 'styled-components';
import { GreenInvertCircleRippleButton } from 'widgets/RippleContainer';
import { HashLink } from "widgets/MyHashLink";

const FABMenu = ({ fabUp = false, waModalShow }) => {
  return (
    <>
      <FABMenuBox id="fab-id" fabUp={fabUp}>
        <HashLink smooth to="/#contacts">
          <GreenInvertCircleRippleButton click={() => { }/* waModalShow */} iconSize={1.25} iconPadding="0 0 2px 2px" iconFAClass="fab fa-whatsapp" rippleColor={"#25D266b0"} darkColor="#128C7E" effect="circle-darken" className="fabutton" /></HashLink>
      </FABMenuBox>
    </>
  );
}

const FABMenuBox = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: calc(14px + 0.6vw);
  z-index: 5;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s;
  transform: ${props => (props.fabUp === true) ? "translateY(-" + "120px" + ")" : "none"};
  /* @media only screen and (max-width: 1000px) {
    transform: ${props => (props.fabUp === true) ? "translateY(-" + "300px" + ")" : "none"};
  } */
  @media only screen and (min-width: 1441px) {
    right: calc((100% - 1440px) / 2.0);
  }
  .fabutton {
    /* margin-left: calc(14px + 0.4vw); */
    transform: translateY(-400px);
    opacity: 0.0;
    animation: fabuttonappear 0.3s ease-out 0.0s 1 forwards;
    @keyframes fabuttonappear {
      0% { transform: translateY(-600px); opacity: 0.0;  }
      50% { opacity: 0.25;  }
      100% { transform: translateY(0px); opacity: 1.0;  }
    }
  }
`;

export default FABMenu;
