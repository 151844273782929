import React, { useState } from 'react';
import styled from 'styled-components';
import { TitleImg1 } from 'widgets/TitleImgs';

export const Expandable0NoClick = ({ src, title = "SOME TITLE", color = "#77CCBB", theme = "dark", align = "left", children = <p>Some content</p>, maxHeight = "200px", ...moreProps }) => {
  const [expanded, setExpanded] = useState(false);
  /* const toggle = () => { setExpanded(e => !e); } */
  return (
    <E0Box expanded={expanded} maxHeight={maxHeight} theme={theme}>
      <TitleImg1 src={src} title={title} color={color} theme={theme} align={align} />
      <div className="e0-content" />
    </E0Box>
  );
}

export const Expandable0 = ({ src, title = "SOME TITLE", color = "#77CCBB", theme = "dark", align = "left", children = <p>Some content</p>, maxHeight = "200px", ...moreProps }) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = () => { setExpanded(e => !e); }
  return (
    <E0Box expanded={expanded} maxHeight={maxHeight} theme={theme}>
      <TitleImg1 style={{ cursor: "pointer" }} src={src} title={title} color={color} onClick={toggle} theme={theme} align={align} />
      <div className="e0-content">
        {children}
      </div>
    </E0Box>
  );
}

const E0Box = styled.div`
  position: relative;
  width: 100%;
  background-color: ${props => (props.theme === "dark") ? "#272121" : ((props.theme === "medium") ? "#989898" : "#f5f5f5")};
  color: ${props => (props.theme === "dark") ? "#ffffffc0" : ((props.theme === "medium") ? "#ffffffd0" : "#000000a0")};
  .e0-content {
    pointer-events: none;
    /* padding: ${props => (props.expanded === true) ? "calc(30px + 1vw) calc(40px + 2vw)" : "0"}; */
    position: relative;
    overflow: hidden;
    width: 100%;
    transition: all 0.3s ease;
    opacity: ${props => (props.expanded === true) ? "1.0" : "0.0"};
    max-height: ${props => (props.expanded === true) ? props.maxHeight : "0px"};
    will-change: max-height, opacity;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
`;
