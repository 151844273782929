import React from 'react';
import styled from 'styled-components';
import { theme0 } from "data/Config";
import SectionRecipes from 'sections/SectionRecipes';
import SectionIngredients from 'sections/SectionIngredients';
import SectionBaking from 'sections/SectionBaking';
import SectionCooks from 'sections/SectionCooks';

const Home = () => {
  return (
    <HomeBox>
      <HomeSection sectionId="recipes" bgcolor={theme0.colorDarkerDark}>
        <SectionRecipes />
      </HomeSection>
      <HomeSection sectionId="ingredients" bgcolor={theme0.colorOffWhite}>
        <SectionIngredients />
      </HomeSection>
      <HomeSection sectionId="baking" style={{ paddingBottom: "0" }} bgcolor={"#989898"}>
        <SectionBaking />
      </HomeSection>
      <HomeSection sectionId="cooks" bgcolor={theme0.colorDarkerDark}>
        <SectionCooks />
      </HomeSection>
    </HomeBox>
  );
}

const HomeBox = styled.div`
  position: relative;
  width: 100%;
  /* min-height: 200vh; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: ${ theme0.colorPrimaryDark};
  /* background-color: #272121; */
`;

const HomeHashTarget = styled.div`
  position: absolute;
  top: ${" -" + theme0.navHeight};
  left: 0px;
  width: 0px;
  height: 0px;
`;

const HomeSectionBox = styled.div`
  position: relative;
  width: 100%;
  /* padding: 0 0 calc(20px + 1vw) 0; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.bgcolor};
`;

const HomeSection = ({ sectionId = "section-id", children = <><br /><br /><br /><br /><br /><br /></>, ...moreProps }) => {
  return (
    <HomeSectionBox {...moreProps}>
      <HomeHashTarget id={sectionId} />
      {children}
    </HomeSectionBox>
  );
}

export default Home;
