import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BlueInvertRippleButton } from "widgets/RippleContainer";
import { theme0 } from "data/Config";
import headerItem0 from "img/header/recipes0-1440-circle.png";
import headerItem1 from "img/header/ingredients0-1440-circle.png";
import headerItem2 from "img/header/baking0-1440-circle.png";
import headerItem3 from "img/header/cooks0-1440-circle.png";
/* import headerItem0 from "img/illu0-850-700.png";
import headerItem1 from "img/illu1-850-700.png";
import headerItem2 from "img/illu2-850-700.png";
import headerItem3 from "img/illu3-850-700.png"; */
import { useKey } from "react-use";
import debounce from "lodash.debounce";
import DotsNav from "widgets/DotsNav";
import { SectionHashTarget } from "utils/Utils";
import { HashLink } from "widgets/MyHashLink";

const headerItems = [
  { title: 'RECIPES', img: headerItem0, text: "Explore the range of products and services we provide. From responsive design, to eCommerce development, to online brand marketing.", ctaTitle: "EXPLORE", ctaIcon: "fas fa-share", ctaLink: "/#recipes" },
  { title: 'INGREDIENTS', img: headerItem1, text: "Discover our frame of mind in laying out the dimensions along which truely tailored solutions unfold, while keeping your goals in focus.", ctaTitle: "UNDERSTAND", ctaIcon: "fas fa-share", ctaLink: "/#ingredients" },
  { title: 'BAKING', img: headerItem2, text: "Understand the essential steps of the workflow, from communication based planning, to careful and regular maintenance.", ctaTitle: "DISCOVER", ctaIcon: "fas fa-share", ctaLink: "/#baking" },
  { title: 'THE COOKS', img: headerItem3, text: "Find out the skills we bring to the table and the values we always keep on that same table. You choose whether it all aligns with your needs.", ctaTitle: "FIND OUT", ctaIcon: "fas fa-share", ctaLink: "/#cooks" }
];

const headerItemsIt = [
  { title: 'RICETTE', img: headerItem0, text: "Esplora la nostra gamma di prodotti e servizi: dal responsive design, allo sviluppo di e-commerce, al marketing online del tuo brand.", ctaTitle: "ESPLORA", ctaIcon: "fas fa-share", ctaLink: "/#recipes" },
  { title: 'INGREDIENTI', img: headerItem1, text: "Scopri i nostri assiomi nell'organizzazione dei progetti, che cci permette di offrire soluzioni personalizzate e tenere in mente i tuoi obiettivi..", ctaTitle: "UNDERSTAND", ctaIcon: "fas fa-share", ctaLink: "/#ingredients" },
  { title: 'COTTURA', img: headerItem2, text: "Scopri come lavoriamoUnderstand the essential steps of the workflow, from communication based planning, to careful and regular maintenance.", ctaTitle: "DISCOVER", ctaIcon: "fas fa-share", ctaLink: "/#baking" },
  { title: 'LA BRIGATA', img: headerItem3, text: "Find out the skills we bring to the table and the values we always keep on that same table. You choose whether it all aligns with your needs.", ctaTitle: "FIND OUT", ctaIcon: "fas fa-share", ctaLink: "/#cooks" }
];

const Header = () => {
  const [item, setItem] = useState(0);
  const itemCount = headerItems.length;
  const swipeOn = debounce(() => { console.log("clicked!"); setItem(i => (i < (itemCount - 1)) ? (i + 1) : 0); }, 10);
  const [boxedText, setBoxedText] = useState(false);
  useEffect(() => { console.log("changed item: ", item); }, [item]);
  useKey("b", () => setBoxedText(state => !state));
  return (
    <HeaderBox onClick={swipeOn}>
      <SectionHashTarget id="start" />
      {
        headerItems.map((cItem, cIndex) =>
          <HeaderSubBox key={"headeritem-" + cIndex} show={item === cIndex} imgFirst={cIndex % 2 !== 0}>
            <HeaderTxtBoxt className="txtbox" boxedText={boxedText} align={(cIndex % 2 !== 0) === true ? "left" : "right"}>
              <h3 onClick={e => e.stopPropagation()}>{cItem.title}</h3>
              <p onClick={e => e.stopPropagation()}>{cItem.text}</p>
              <HashLink to={cItem.ctaLink} smooth><BlueInvertRippleButton text={cItem.ctaTitle} icon={cItem.ctaIcon} /></HashLink>
            </HeaderTxtBoxt>{/* <HashLink to={cItem.ctaLink} smooth> */}
            <HeaderImgBox /* onClick={(e) => e.stopPropagation()} */ src={cItem.img} className="imgbox" imgFirst={cIndex % 2 !== 0}>
              <img src={cItem.img} alt={"BITSCUITS " + cItem.title} />
            </HeaderImgBox>{/* </HashLink> */}
          </HeaderSubBox>
        )
      }
      <HeaderDots onClick={(e) => e.stopPropagation()}>
        <DotsNav marginPx={4} borderPx={0} dotsNumber={headerItems.length} sizePx={23} activeDot={((item > -1) && (item < headerItems.length)) ? item : 0} dotsCallback={(ind) => { setItem(ind); }} color="#77ccbb60" colorActive="#77ccbb" />
      </HeaderDots>
    </HeaderBox>
  );
}

const HeaderDots = styled.div`
  position: absolute;
  left: 50%;
  bottom: 25px;
  transform: translate(-50%, 0%);
  padding: 5px 10px;
  z-index: 2;
  opacity: 1.0;
  transition: all 0.3s;
  /* background-color: #ffffffc0;
  border-radius: 22px;
  box-shadow: 4px 4px 4px #00000030; */
  @media only screen and (max-width: 800px) {
    /* bottom: unset;
    box-shadow: unset;
    background-color: unset; */
    /* background-color: #ffffffc0; */
    background-color: #00000080;
    border-radius: 22px;
    box-shadow: 4px 4px 4px #00000030;
    /* top: ${"calc(" + theme0.navFatHeight + " + 10px)"}; */
  }
  @media only screen and (max-width: 330px) {
    bottom: 15px;
  }
`;

const HeaderBox = styled.div`
  user-select: none;
  position: relative;
  width: 100%;
  /* height: 100%; */
  height: 100vh;
  max-height: 800px;

  /* overflow: hidden; */
  /* background-color: #ffffff; */
  background-color: #272121;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  opacity: 0.0;
  animation: headerappear 1s ease 0.3s 1 forwards;
  @keyframes headerappear {
    0% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
`;

const HeaderSubBox = styled.div`
  position: absolute;
  top: ${theme0.navFatHeight};
  left: 5%;
  width: 90%;
  @media only screen and (max-width: 900px) {
    width: 100%;
    left: 0%;
  }
  transition: opacity 0.3s, display 1s;
  display: ${props => props.show === true ? "flex" : "none"};
  opacity: ${props => props.show === true ? "1.0" : "0.0"};
  .imgbox {
    transition: all 0.5s;
    transform: ${props => props.show === true ? "translate(0vw, 0)" : (props.imgFirst === false ? "translate(-100vw, 0)" : "translate(100vw, 0)")};
  }
  .txtbox {
    transition: all 0.5s;
    transform: ${props => props.show === true ? "translate(0, 0vh)" : (props.imgFirst === false ? "translate(0, 100vh)" : "translate(0, -100vh)")};
  }
  height: ${"calc(100% - " + theme0.navFatHeight + ")"};
  /* background-color: #ffffff; */
  background-color: #272121;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

const HeaderTxtBoxt = styled.div`
  position: relative;
  order: 1;
  width: calc(100% - (250px + 24vw));
  max-width: 700px;
  @media only screen and (min-width: 1441px) {
    width: calc(100% - 586px);
    max-width: 700px;
  }
  padding: calc(20px + 2vw);
  z-index: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: ${props => props.align === "left" ? "flex-start" : (props.align === "right" ? "flex-end" : "center")};
  text-align: ${props => props.align === "left" ? "start" : (props.align === "right" ? "end" : "center")};
  cursor: initial;
  &>h3 {
    font-size: calc(20px + 1vw);
    font-weight: 500;
    line-height: 1;
    color: #77ccbb;
    margin-bottom: calc(15px + 1.5vw);
  }
  &>p {
    font-size: calc(13px + 0.4vw);
    font-weight: 400;
    line-height: 1.6;
    /* color: #505050; */
    color: #d0d0d0;
    margin-bottom: calc(15px + 1.5vw);
  }
  @media only screen and (max-width: 800px) {
    width: ${props => props.boxedText === true ? "calc(400px + 20vw)" : "100%"};
    max-width: unset;
    margin: 0;
    border: none;
    align-items: center;
    text-align: center;
    /* background-color: #ffffffe8; */
    background-color: #272121e8;
    box-shadow: 0px 3px 6px #000000b0;
    padding: ${props => props.boxedText === true ? "calc(20px + 2vw)" : "calc(40px + 2vw) calc((100% - 500px ) / 2.0)"};
    border-radius: ${props => props.boxedText === true ? "50px" : "0"};
  }
  @media only screen and (max-width: 560px) {
    width: ${props => props.boxedText === true ? "90%" : "100%"};
    padding: calc(40px + 2vw) calc(20px + 2vw);
    &>h3 {font-size: 25px;}
    &>p {font-size: 14px;}
  }
`;

const HeaderImgBox = styled.div`
  position: relative;
  order: ${props => props.imgFirst === true ? "0" : "2"};
  width: calc(250px + 24vw);
  @media only screen and (min-width: 1441px) {
    width: 586px;
  }
  height: 100%;
  z-index: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  img {
    /* width: 90%;
    height: auto; */
    height: 85%;
    width: auto;
    border-radius: 50px;
  }
  @media only screen and (max-width: 800px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* background-image: url(${props => props.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    img {
      /* display: none; */
      position: absolute;
      height: 94%;
      width: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @media only screen and (max-width: 560px) {}
`;

export default Header;
