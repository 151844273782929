import React, { useState } from 'react';
import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';
import { toolsList } from 'data/ToolsList';
import debounce from "lodash.debounce";
/* import { CardScroller0 } from "widgets/CardScrollers"; */

/* {
  name: "Adobe Photoshop",
  short: "Photoediting, vector and raster graphics editor",
  imgurl: "adobe-photoshop-photo-editing-raster-vector-graphics-website-design-responsive-layout-development.png",
  descr: "Adobe Photoshop is a ...",
  url: "https://www.adobe.com/products/photoshop.html"
} */

export const CardScroller0 = ({ items, scrollerHeight = "400px", /* CardContent,  */itemClick = (index) => console.log("clicked item number: ", index), ...moreProps }) => {
  const [tools, setTools] = useState([...toolsList]);
  const toggleTool = debounce((toolIndex) => {
    setTools(ts => {
      let mutatedTools = [...ts];
      mutatedTools[toolIndex].flip = !mutatedTools[toolIndex].flip;
      return mutatedTools;
    });
  }, 20);
  return (
    <HGBox scrollerHeight={scrollerHeight} {...moreProps}><HGBoxStart /><HGBoxEnd />
      <ScrollContainer className="scroll-container">
        <HGSubBox>
          {
            items.map((item, index) =>
              <HGItem flipped={item.flip} key={"cscroll-" + index} onClick={() => toggleTool(index)/* itemClick(index) */}>
                <CardBox className="flip-card-inner">
                  <div className="card-front">
                    <img src={"img/tools/" + item.imgurl} alt={item.name} />
                  </div>
                  <div className="card-back">
                    <h3>{item.name}</h3>
                    <p>{item.short}</p>
                    <a href={item.url} target="_blank" rel="noopener noreferrer">DISCOVER</a>
                  </div>
                </CardBox>
              </HGItem>
            )
          }
        </HGSubBox>
      </ScrollContainer>
    </HGBox>
  );
}

const HGItem = styled.div`
  position: relative;
  width: calc(256px + 4vw);
  height:/*  calc(100% - 30px); */calc(256px + 4vw);
  /* border-radius: 5px 40px 5px 40px; */
  overflow: hidden;
  margin: 0 calc(10px + 0.2vw);
  padding: calc(18px + 0.3vw);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  /* transform: scale(1.00); */
  /* opacity: 0.8; */
  /* transition: all 0.4s; */
  /* background: #ffffff28; */
  /* @media(hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.05);
      opacity: 1.0;
    }
  } */
  cursor: pointer;
  perspective: 1000px;
  /* @media(hover: hover) and (pointer: fine) {
    &:hover { */
      .flip-card-inner {
        transform: ${props => props.flipped === true ? "rotateY(180deg)" : "rotateY(0deg)"};
      }
    /* }
  } */


  /* background-color: transparent; */
  /* width: 300px;
  height: 300px; */
`;

const CardBox = styled.div`
  /* position: relative;
  max-width: 180px;
  height: calc(40% + 100px); */
  /* background-image: linear-gradient(180deg, #36333300, #363333, #272121); */
  /* border-radius: 5px 40px 5px 40px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 90%; */
  /* text-align: center; */
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 1.4;
  transition: transform 0.5s ease;
  transform-style: preserve-3d;
  .card-front, .card-back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .card-front {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    img {
      width: 90%;
      height: auto;
      max-height: 90%;
      /* position: absolute; */
      /* left: 50%;
      top: 100px;
      transform: translate(-50%, calc(-50% - 50px)); */
    }
  }
  .card-back {
    transform: rotateY(180deg);
    background-image: linear-gradient(180deg, #363333, #272121);
    /* background-image: linear-gradient(180deg, #36333300, #363333, #272121); */
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 10%;
    h3 {
      font-size: calc(14px + 0.4vw);
      font-weight: 600;
      /* color: #fffffff0; */
      color: #77ccbb;
    }
    p {
      font-size: calc(12px + 0.2vw);
      font-weight: 400;
      color: #ffffffa0;
      margin: 10px 0;
    }
    a {
      color: #A87E2A;
      font-size: calc(11px + 0.18vw);
      font-weight: 500;
      @media(hover: hover) and (pointer: fine) {
        &:hover {
          color: #ffbb33;
        }
      }
    }
    @media only screen and (min-width: 1441px) {
      h3 {font-size: 18px;}
      p {font-size: 15px;}
    }
  }
`;

const HGBox = styled.div`
  background-color: #000000;
  /* border-top: 1px solid #999;
  border-bottom: 1px solid #999; */
  position: relative;
  width: 100%;
  padding: /* calc(0px + 0.4vw) */ 0;
  display: grid;
  place-items: center;
  height: /* calc(286px + 5vw); */calc(276px + 4vw);
  /* height: ${props => (props.scrollerHeight && props.scrollerHeight !== "") ? props.scrollerHeight : "300px"}; */
  /* overflow: hidden; */
  .scroll-container {
    position: relative;
    width: 100%;
    height: 100%;
    /* height: calc(118px + 6.4vw); */
  }
  /* @media only screen and (min-width: 1441px) {
    .scroll-container {
      height: 380px;
    }
  }
  @media only screen and (max-width: 400px) {
    height: 290px;
  } */
`;

const HGSubBox = styled.div`
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  /* height: 100%; */
  padding: 0 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const HGBoxStart = styled.div`
  pointer-events: none;
  width: calc(20px + 6vw);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #000000ff, #000000c0, #00000000);
  z-index: 1;
`;

const HGBoxEnd = styled(HGBoxStart)`
  left: unset;
  right: 0;
  background-image: linear-gradient(to left, #000000ff, #000000c0, #00000000);
`;

const Tools = ({ ...moreProps }) => {
  return (
    <ToolsBox {...moreProps}>
      <CardScroller0 /* style={{ marginBottom: "calc(20px + 1vw)" }} */ items={toolsList} /* CardContent={Card} */ /* itemClick={itemClick} */ />
    </ToolsBox>
  );
}

const ToolsBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export default Tools;
