import styled, { css } from 'styled-components';

export const theme0 = {
  colorPrimaryDark: "#363333",
  colorDarkerDark: "#272121",
  colorAccentOrange: "#e16428",
  colorOffWhite: "#f5f5f5",
  colorOffWhite2: "#f6e9e9",

  appMaxWidth: "1440px",
  appMinOverWidth: "1441px",
  /* appMaxWidth: "1024px",
  appMinOverWidth: "1025px", */

  footerHeight: "120px",
  footerFatHeight: "120px",
  footerFatMaxWidth: "900px",

  /* navHeight: "120px", */
  navHeight: "96px",
  navFatHeight: "140px",
  navLogoHeight: "85px",
  navFatLogoHeight: "115px",
  navVanishAtHomeTop: false,

  colorPrimary: "#77ccbb",
  colorAccent: "#ffbb33",
  /* colorNavBackground: "#ffffffd0", */
  colorNavBackground: "#000000e0",
  styleFirstLetter: css`&::first-letter { color: #30a2ff; }`
};

export const styleGlobalFirstLetter = css`
  &::first-letter { color: #30a2ff; }
`;

export const SomeStyle = styled.div`
  ${styleGlobalFirstLetter};
  color: ${props => props.theme.primary};
  ${props => props.theme.styleFirstLetter};
  ${props => props.align === "left" && css`
    left: 0;
    transform: translate(-25%, -40%);
  `};
`;
