import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { SectionHashTarget } from "utils/Utils";

export const TitleImg1 = ({ src, title = "SOME TITLE", color = "#77CCBB", theme = "dark", align = "left", ...moreProps }) => {
  return (
    <TIB1 {...moreProps} color={color} src={src} theme={theme} align={align}>
      <div><div className="overlay" /></div>
      <h3>{title}</h3>
    </TIB1>
  );
}

const TIB1 = styled.div`
  position: relative;
  width: 100%;
  /* height: calc(500px * (275 / 1024)) */
  height: calc((280px + 18vw) * (275 / 1024));
  user-select: none;
  &>div {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* opacity: 0.75; */
    .overlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-image: ${props => props.theme === "dark" ? ("linear-gradient(to " + props.align + ", #27212130, #272121c0, #272121e0)") : (props.theme === "medium" ? ("linear-gradient(to " + props.align + ", #98989830, #989898c0, #989898e0)") : ("linear-gradient(to " + props.align + ", #f5f5f530, #f5f5f5c0, #f5f5f5e0)"))};
    }
  }
  h3 {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /*
  letter-spacing: calc(5px + 0.7vw);
  line-height: 1.25;
  font-size: calc(30px + 3vw);
  @media only screen and (max-width: 500px) {
    letter-spacing: calc(4px + 0.4vw);
    font-size: calc(28px + 2vw);
  }
  @media only screen and (max-width: 400px) {
    letter-spacing: calc(4px + 0.4vw);
    font-size: calc(26px + 1.8vw);
  }
  @media only screen and (max-width: 330px) {
    letter-spacing: calc(4px + 0.4vw);
    font-size: calc(24px + 1vw);
  }
  */
    font-size: calc(22px + 1vw);
    letter-spacing: calc(4px + 0.6vw);
    @media only screen and (max-width: 600px) {
      font-size: calc(14px + 1.6vw);
      letter-spacing: calc(2px + 0.8vw);
    }
    font-weight: 500;
    text-transform: uppercase;
    color: ${props => props.theme === "dark" ? "#ffffffe0" : (props.theme === "medium" ? "#ffffffe0" : "#000000a0")};
    text-align: ${props => props.align === "left" ? "start" : "end"};
    padding: ${props => props.align === "left" ? "0 0 0 calc(30px + 2vw)" : "0 calc(30px + 2vw) 0 0"};
    @media only screen and (max-width: 400px) {
      padding: ${props => props.align === "left" ? "0 0 0 20px" : "0 20px 0 0"};
    }
    &::first-letter { color: ${props => props.color}; }
  }
  background-color: ${props => props.theme === "dark" ? "#272121" : "f5f5f5"};
  padding: calc(30px + 1.5vw) 0;
`;

export const TitleImg0 = ({ sectionId, src, title = "SOME TITLE", color = "#77CCBB", titleYPercent = "50%", theme = "dark", ...moreProps }) => {
  const [thisRef, thisInView,] = useInView({ threshold: 0.25, triggerOnce: true });
  return (
    <TIB0 ref={thisRef} inview={thisInView} {...moreProps} color={color} src={src} theme={theme} titleYPercent={titleYPercent}>
      <div>{(sectionId && sectionId !== "") && <SectionHashTarget id={sectionId} />}{/* <div className="lineargradient" /><div className="radialgradient" /> */}</div>
      <h2>{title}</h2>
    </TIB0>
  );
}

const TIB0 = styled.div`
  position: relative;
  width: 100%;
  max-width: 768px;
  /* height: calc(500px * (688 / 1024));
  max-height: 70vw; */
  height: 44vw;
  max-height: 336px;
  margin: calc(10px + 1vw) 0;
  user-select: none;
  background-color: ${props => props.theme === "dark" ? "#272121" : (props.theme === "medium" ? "#989898" : "f5f5f5")};
  padding: calc(30px + 1.5vw) 0;
  opacity: 0.0;
  animation: ${props => props.inview === true ? "appear 0.8s linear 0.0s 1 forwards" : "none"};
  @keyframes appear {
    0% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
  &>div {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    /* opacity: 0.85; */
    .lineargradient {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      /* background-image: ${props => props.theme === "dark" ? "linear-gradient(to bottom, #272121ff, #27212150, #272121ff)" : (props.theme === "medium" ? "linear-gradient(to bottom, #989898ff, #98989850, #989898ff)" : "linear-gradient(to bottom, #f5f5f5ff, #f5f5f540, #f5f5f5ff)")}; */
      /* background-image: ${props => props.theme === "dark" ? "linear-gradient(to bottom, #272121ff, #27212150, #272121ff)" : (props.theme === "medium" ? "linear-gradient(to bottom, #989898ff, #98989830, #989898ff)" : "linear-gradient(to bottom, #f5f5f5ff, #f5f5f530, #f5f5f5ff)")}; */
      background-image: ${props => props.theme === "dark" ? "linear-gradient(to bottom, #272121ff, #27212160, #272121ff)" : (props.theme === "medium" ? "linear-gradient(to bottom, #989898ff, #98989838, #989898ff)" : "linear-gradient(to bottom, #f5f5f5ff, #f5f5f538, #f5f5f5ff)")};
    }
    .radialgradient {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      /* background-image: ${props => props.theme === "dark" ? "radial-gradient(#27212170, #272121ff 96%)" : (props.theme === "medium" ? "radial-gradient(#98989870, #989898ff 96%)" : "radial-gradient(#f5f5f550, #f5f5f5ff 96%)")}; */
      background-image: ${props => props.theme === "dark" ? "radial-gradient(circle at center, #27212150, #27212170, #272121ff 86%)" : (props.theme === "medium" ? "radial-gradient(circle at center, #98989828, #98989838, #989898ff 86%)" : "radial-gradient(circle at center, #f5f5f528, #f5f5f538, #f5f5f5ff 86%)")};
    }
  }
  h2 {
    width: 100%;
    position: absolute;
    top: ${props => props.titleYPercent};
    transform: translateY(-250%);
    font-size: calc(30px + 1vw);
    letter-spacing: calc(6px + 1.4vw);
    opacity: 0.0;
    animation: ${props => props.inview === true ? "fallinplace 0.5s ease 0.0s 1 forwards" : "none"};
    @keyframes fallinplace {
      0% { transform: translateY(-250%); opacity: 0.0; }
      100% { transform: translateY(-50%); opacity: 1.0; }
    }
    @media only screen and (max-width: 500px) {
      font-size: calc(20px + 2vw);
    }
    @media only screen and (max-width: 400px) {
      font-size: calc(16px + 3vw);
      letter-spacing: calc(4px + 1.2vw);
    }
    @media only screen and (max-width: 330px) {
      font-size: calc(12px + 4.4vw);
    }
    font-weight: 600;
    text-transform: uppercase;
    color: ${props => props.theme === "dark" ? "#ffffffc0" : (props.theme === "medium" ? "#ffffffe8" : "#000000d0")};
    text-align: center;
    &::first-letter { color: ${props => props.color}; }
  }
`;
