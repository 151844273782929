import React, { useState } from 'react';
import styled from 'styled-components';
/* import imgGoals from 'img/imgs0/goals.jpg';
import imgStandout from 'img/imgs0/standout.jpg';
import imgProjection from 'img/imgs0/projection.jpg'; */
import imgGoals from 'img/imgs00/goals-tiny.jpg';
import imgStandout from 'img/imgs00/standout-tiny.jpg';
import imgProjection from 'img/imgs00/projection-tiny.jpg';
import { TitleImg0 } from 'widgets/TitleImgs';
import { MultiSnippet3 } from "widgets/MultiSnippet";
import { TextBlock1 } from "widgets/TextBlocks";
import { Headline0 } from 'widgets/Headlines';
import DoubleContent from "widgets/DoubleContent";

const SIBox = styled.div`
  position: relative;
  width: 100%;
  padding: ${props => props.expanded === true ? "0 0 calc(20px + 1vw) 0" : "0"};
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  overflow: hidden;
  transition: all 0.3s;
  opacity:  ${props => props.expanded === true ? "1.0" : "0.0"};
  max-height: ${props => props.expanded === true ? "10000px" : "0px"};
`;

const SectionIngredients = () => {
  /* const [expanded, setExpanded] = useState(true); */
  const [expanded, setExpanded] = useState(false);
  const toggle = () => { setExpanded(e => !e); }
  return (
    <>
      <Headline0 onClick={toggle} color={"#ffbb33"} theme={"light"}>INGREDIENTS<i className={expanded === true ? "fas fa-minus" : "fas fa-plus"} /></Headline0>
      <SIBox expanded={expanded}>
        <TextBlock1 style={{ marginTop: "calc(30px + 1vw)" }} accentColor="#77ccbb" theme="light"><p>Several ingredients make up our recipe and combine to define an accurate and explicit development plan.</p><div className="jump" /></TextBlock1>
        <TitleImg0 theme="light" color={"#389482"} titleYPercent={"80%"} src={imgGoals} title={"CONCEPTION"} />
        <TextBlock1 accentColor="#77ccbb" theme="light"><div className="jump" /><p>The blueprinting process begins by outlining you main ideas, which we break down into <strong>goals, constraints and priorities</strong>. Such differentiation is crucial to avoid potential time sinks, e.g. contentless layout tweaking, editing of outdated images.</p></TextBlock1>
        <MultiSnippet3 columnThreshold="750px" theme="light" color="#77ccbb" titles={[
          "GOALS", "CONSTRAINTS", "PRIORITIES",
        ]} icons={[
          "fas fa-bullseye", "fas fa-wave-square", "fas fa-exclamation",
        ]} texts={[
          "Laying out ultimate goals and outlining what actions you wish users take on your website",
          "Budget, time, technology and device-related constraints are taken into account",
          "From visually conveying a certain mood to maximizing search engine positioning, goals and priorities must be analysied side by side"
        ]} />
        <TitleImg0 sectionId="standout" theme="light" color={"#E59900"} titleYPercent={"82.5%"} src={imgStandout} title={"STAND OUT"} />
        <TextBlock1 accentColor="#77ccbb" theme="light"><div className="jump" /><p><strong>Competitor analysis</strong> largely contributes to laying out the content strategy with ease: having a reference in terms of user interface and content type is key to avoid getting lost in the sea of possibilities. Once the average competitor is synthesized, it becomes trivial to identify and highlight the differentiating and <strong>selling factors</strong> of your website.</p><div className="jjump" /></TextBlock1>
        <DoubleContent theme="light" childs={[
          <><strong>REFERENCE</strong><p>Use competitor websites as a reference to prevent getting lost in the sea of possibilities and build an ideal in terms of textual and media assets</p></>,
          <><strong>DIFFERENCE</strong><p>Use it as a differentiation factor: once you have a median sample of content type, it becomes easier to identify and highlight points of focus in the assets/information you want to offer</p></>
        ]} />
        <TitleImg0 theme="light" color={"#389482"} titleYPercent={"70%"} src={imgProjection} title={"PROJECTION"} />
        <TextBlock1 accentColor="#77ccbb" theme="light"><p>The third and final ingredient for blueprinting is the final visitor. <strong>Target audience</strong> persona are identified, as are their ultimate needs: such needs are then translated into what users are likely to be looking for on the website. This projection is used to modulate pieces of content to provide on the website, e.g. instant buy options, in-depth articles.</p></TextBlock1>
      </SIBox>
    </>
  );
}

export default SectionIngredients;
