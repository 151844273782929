import React from 'react';
import styled from 'styled-components';
import { HashLink } from "widgets/MyHashLink";
import { SectionHashTarget } from "utils/Utils";

export const TextBlock1 = ({ children = <><p>Tutto l’anno sono il mix di <strong>prodotti-servizi</strong> che ci hanno permesso di consolidare sempre più la nostra leadership in questa <em>splendida location</em> che entusiasma la mia affiatatissima squadra e più di 60000 altri buoni e <HashLink smooth to="/#contatti">soddisfatti</HashLink> clienti.</p><br /><p className="muted">Tutto ciò dipende dall'intelligenza del cliente</p><div className="jump" />{/* <BlueInvertRippleButton /> */}<div className="jump" /></>, theme = "dark", sectionId,
  verticalSpacing = false, outTitle, inTitle, paramWidth, maxWidth, textAlign = "center", accentColor = "#ffbb33", ...moreProps }) => {
  return (
    <TextBlock1Box theme={theme} computedMargin={verticalSpacing ? (verticalSpacing === true ? "calc(14px + 1vw) 0" : ("" + verticalSpacing + " 0")) : "0"} paramWidth={paramWidth} maxWidth={maxWidth} textAlign={textAlign} inTitle={inTitle}
      accentColor={accentColor} {...moreProps}>
      {(sectionId && sectionId !== "") && <SectionHashTarget id={sectionId} />}
      {(outTitle && outTitle !== "") && <h3>{outTitle}</h3>}
      {(inTitle && inTitle !== "") && <h4>{inTitle}</h4>}
      {children}
    </TextBlock1Box>
  );
}

const TextBlock1Box = styled.div`
  position: relative;
  width: ${props => (props.paramWidth && props.paramWidth !== "") ? props.paramWidth : "90%"};
  max-width: ${props => (props.maxWidth && props.maxWidth !== "") ? props.maxWidth : "1100px"};
  text-align: ${props => (props.textAlign === "left" || props.textAlign === "start") ? "start" : ((props.textAlign === "right" || props.textAlign === "end") ? "end" : "center")};
  font-size: calc(13px + 0.3vw);
  line-height: 1.65;
  font-weight: 400;
  color: ${props => props.theme === "dark" ? "#ffffffc0" : (props.theme === "medium" ? "#ffffffd0" : "#000000b0")};
  margin: ${props => props.computedMargin};
  &>* {position: relative;}
  h3 {
    font-size: calc(20px + 1.0vw);
    line-height: 1.4;
    margin-bottom: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #202020;
    &::first-letter {
      color: ${props => props.accentColor !== "" ? props.accentColor : "#ffbb33"};
    }
  }
  h4 {
    display: inline-block;
    font-size: calc(18px + 0.8vw);
    color: #aaaaaa;
    letter-spacing: 2px;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 4px;
    &::first-letter {
      color: ${props => props.accentColor !== "" ? props.accentColor : "#ffbb33"};
    }
  }
  p {display: inline;}
  strong {
    color: ${props => props.theme === "dark" ? "#ffffffe0" : (props.theme === "medium" ? "#fffffff0" : "#000000d0")};
    letter-spacing: 1px;
    font-weight: 600;
  }
  em {
    letter-spacing: 1px;
    color: ${props => props.theme === "dark" ? "#ffffffe0" : (props.theme === "medium" ? "#fffffff0" : "#000000d0")};
    font-weight: 500;
  }
  a {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 0%;
      height: 2px;
      background-color: ${props => props.accentColor !== "" ? props.accentColor : "#ffbb33"};
      bottom: -2px;
      left: 50%;
      transition: all .25s ease-out;
    }
    color: ${props => props.accentColor !== "" ? props.accentColor : "#ffbb33"};
    text-decoration: none;
    font-weight: 600;
    transition: color .25s ease-out;
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        &::after {
          left: 0%;
          width: 100%;
        }
      }
    }
  }
  .muted {
    color: #aaaaaa;
    font-size: calc(12px + 0.5vw);
  }
  .jump {height: calc(1.65 * (13px + 0.4vw));}
  .jjump {height: calc(3 * (13px + 0.4vw));}
  @media only screen and (min-width: 1441px) {
    margin: ${props => props.computedMargin === "0" ? "0" : "40px 0"};
  }
`;
