import React, { useState } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { theme0 } from 'data/Config';

export const MultiSnippet3Small = ({ titles = ["LOL ECCE", "PORPOSYCHY", "ECCE HOMO", "ECCE HOMO"], icons = ["fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"], color = theme0.colorPrimary, columnMarginBottom, columnThreshold = "900px", theme = "dark", ...moreProps }) => {
  const [thisRef, thisInView,] = useInView({ threshold: 0.25, triggerOnce: true });
  return (
    <MS3BoxSmall columnThreshold={columnThreshold} ref={thisRef} {...moreProps}>
      <MS3ItemSmall inview={thisInView} index={0} columnMarginBottom={columnMarginBottom} columnThreshold={columnThreshold} theme={theme} color={color}><i className={icons[0]} /><h3>{titles[0]}</h3></MS3ItemSmall>
      <MS3ItemSmall inview={thisInView} index={1} columnMarginBottom={columnMarginBottom} columnThreshold={columnThreshold} theme={theme} color={color}><i className={icons[1]} /><h3>{titles[1]}</h3></MS3ItemSmall>
      <MS3ItemSmall inview={thisInView} index={2} columnMarginBottom={columnMarginBottom} columnThreshold={columnThreshold} theme={theme} color={color}><i className={icons[2]} /><h3>{titles[2]}</h3></MS3ItemSmall>
    </MS3BoxSmall>
  );
}

const MS3BoxSmall = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: calc(12px + 1vw) 0;
  @media only screen and (min-width: 1441px) {margin: 26px 0;}
`;

const MS3ItemSmall = styled.div`
  position: relative;
  height: auto;
  width: 25%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: calc(6px + 0.5vw) 0;
  transform: translateX(-200%);
  opacity: 0.0;
  animation: ${props => props.inview === true ? "fallinplace3 0.6s ease " + ((props.index || 0) * 0.2) + "s 1 forwards" : "none"};
  @keyframes fallinplace3 {
    0% { transform: translateX(-200%); opacity: 0.0; }
    100% { transform: translateX(-0%); opacity: 1.0; }
  }
  &>i {
    font-size: calc(16px + 1.4vw);
    @media only screen and (min-width: 1025px) {
      font-size: 32px;
    }
    color: ${props => props.color + "90"};
  }
  &>h3 {
    font-size: calc(12px + 0.1vw);
    line-height: 1.25;
    font-weight: 600;
    color: ${props => props.theme === "dark" ? "#ffffff90" : (props.theme === "medium" ? "#ffffffa0" : "#000000a8")};
    letter-spacing: 1px;
    margin: calc(12px + 0.12vw) 0 calc(10px + 0.06vw) calc(12px + 0.12vw);
  }
  @media only screen and (max-width: 330px) {
    &>h3 {font-size: 12px;}
  }
  @media only screen and (max-width: ${props => props.columnThreshold}) {
    width: 90%;
    max-width: 460px;
    margin-bottom: ${props => props.columnMarginBottom !== "" ? props.columnMarginBottom : "initial"};
  }
  @media only screen and (min-width: 1441px) {
    margin: 13px 0;
    &>h3 {
      font-size: 14px;
      margin: 16px 0 13px 0;
    }
  }
`;

export const MultiSnippet3 = ({ titles = ["LOL ECCE", "PORPOSYCHY", "ECCE HOMO", "ECCE HOMO"], icons = ["fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"], texts = ["consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada."], color = theme0.colorPrimary, columnMarginBottom, columnThreshold = "900px", theme = "dark", ...moreProps }) => {
  const [thisRef, thisInView,] = useInView({ threshold: 0.25, triggerOnce: true });
  return (
    <MS3Box ref={thisRef} {...moreProps}>
      <MS3Item onClick inview={thisInView} index={0} columnMarginBottom={columnMarginBottom} columnThreshold={columnThreshold} theme={theme} color={color}><i className={icons[0]} /><h3>{titles[0]}</h3><p>{texts[0]}</p></MS3Item>
      <MS3Item inview={thisInView} index={1} columnMarginBottom={columnMarginBottom} columnThreshold={columnThreshold} theme={theme} color={color}><i className={icons[1]} /><h3>{titles[1]}</h3><p>{texts[1]}</p></MS3Item>
      <MS3Item inview={thisInView} index={2} columnMarginBottom={columnMarginBottom} columnThreshold={columnThreshold} theme={theme} color={color}><i className={icons[2]} /><h3>{titles[2]}</h3><p>{texts[2]}</p></MS3Item>
    </MS3Box>
  );
}

const MS3Box = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: calc(12px + 1vw) 0;
  @media only screen and (min-width: 1441px) {margin: 26px 0;}
`;

const MS3Item = styled.div`
  position: relative;
  height: auto;
  width: 25%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: calc(12px + 1vw) 0;

  transform: translateX(-200%);
  opacity: 0.0;
  animation: ${props => props.inview === true ? "fallinplace3 0.6s ease " + ((props.index || 0) * 0.2) + "s 1 forwards" : "none"};
  @keyframes fallinplace3 {
    0% { transform: translateX(-200%); opacity: 0.0; }
    100% { transform: translateX(-0%); opacity: 1.0; }
  }
  &>i {
    font-size: calc(32px + 2vw);
    @media only screen and (min-width: 1025px) {
      font-size: 50px;
    }
    color: ${props => props.color + "c0"};
  }
  &>h3 {
    /* font-size: calc(16px + 0.25vw); */
    font-size: calc(1.2 * (13px + 0.1vw));
    line-height: 1.25;
    font-weight: 600;
    color: ${props => props.theme === "dark" ? "#ffffffc0" : (props.theme === "medium" ? "#ffffffd0" : "#00000078")};
    letter-spacing: 1px;
    &::first-letter { color: ${props => "" + props.color + "e8"}; }
    margin: calc(12px + 0.12vw) 0 calc(10px + 0.06vw) 0;
    /* @media only screen and (max-width: 330px) {
      font-size: calc(12px + 1vw);
    } */
  }
  &>p {
    /* font-size: calc(12px + 0.25vw); */
    font-size: calc(13px + 0.1vw);
    line-height: 1.5;
    font-weight: 400;
    color: ${props => props.theme === "dark" ? "#ffffffb0" : (props.theme === "medium" ? "#ffffffc0" : "#00000090")};
  }
  @media only screen and (max-width: 330px) {
    &>h3 {font-size: 15px;}
    &>p {font-size: 13px;}
  }
  @media only screen and (max-width: ${props => props.columnThreshold}) {
    width: 90%;
    max-width: 460px;
    margin-bottom: ${props => props.columnMarginBottom !== "" ? props.columnMarginBottom : "initial"};
  }
  @media only screen and (min-width: 1441px) {
    margin: 26px 0;
    &>i {font-size: 60px;}
    &>h3 {
      font-size: 20px;
      margin: 16px 0 13px 0;
    }
    &>p {font-size: 16px;}
  }
`;

export const MultiSnippet4 = ({ titles = ["LOL ECCE", "PORPOSYCHY", "ECCE HOMO", "ECCE HOMO"], icons = ["fas fa-clock", "fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"], texts = ["sit amet, consectetur adipiscing elit. Donec id finibus tellus, at finibus sem. Morbi eleifend malesuada nunc.", "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada."], color = theme0.colorPrimary, theme = "dark", columnThreshold1 = "1100px", columnThreshold2 = "700px", ...moreProps }) => {
  const [thisRef, thisInView,] = useInView({ threshold: 0.25, triggerOnce: true });
  return (
    <MS4Box ref={thisRef} {...moreProps}>
      <MS4Item inview={thisInView} index={0} columnThreshold1={columnThreshold1} columnThreshold2={columnThreshold2} theme={theme} color={color}><i className={icons[0]} /><h3>{titles[0]}</h3><p>{texts[0]}</p></MS4Item>
      <MS4Item inview={thisInView} index={1} columnThreshold1={columnThreshold1} columnThreshold2={columnThreshold2} theme={theme} color={color}><i className={icons[1]} /><h3>{titles[1]}</h3><p>{texts[1]}</p></MS4Item>
      <MS4Item inview={thisInView} index={2} columnThreshold1={columnThreshold1} columnThreshold2={columnThreshold2} theme={theme} color={color}><i className={icons[2]} /><h3>{titles[2]}</h3><p>{texts[2]}</p></MS4Item>
      <MS4Item inview={thisInView} index={3} columnThreshold1={columnThreshold1} columnThreshold2={columnThreshold2} theme={theme} color={color}><i className={icons[3]} /><h3>{titles[3]}</h3><p>{texts[3]}</p></MS4Item>
    </MS4Box>
  );
}

const MS4Box = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: calc(12px + 1vw) 0;
  @media only screen and (min-width: 1441px) {margin: 26px 0;}
`;

const MS4Item = styled.div`
  position: relative;
  height: auto;
  width: 20%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: calc(12px + 1vw) 0;

  transform: translateX(-200%);
  opacity: 0.0;
  animation: ${props => props.inview === true ? "fallinplace4 0.6s ease " + ((props.index || 0) * 0.2) + "s 1 forwards" : "none"};
  @keyframes fallinplace4 {
    0% { transform: translateX(-200%); opacity: 0.0; }
    100% { transform: translateX(-0%); opacity: 1.0; }
  }
  &>i {
    font-size: calc(32px + 2vw);
    @media only screen and (min-width: 1025px) {
      font-size: 50px;
    }
    color: ${props => props.color + "c0"};
  }
  &>h3 {
    /* font-size: calc(16px + 0.25vw); */
    font-size: calc(1.2 * (13px + 0.1vw));
    line-height: 1.25;
    font-weight: 600;
    color: ${props => props.theme === "dark" ? "#ffffffc0" : (props.theme === "medium" ? "#ffffffb0" : "#00000078")};
    letter-spacing: 1px;
    &::first-letter { color: ${props => "" + props.color + "e8"}; }
    margin: calc(12px + 0.12vw) 0 calc(10px + 0.06vw) 0;
  }
  &>p {
    /* font-size: calc(12px + 0.25vw); */
    font-size: calc(13px + 0.1vw);
    line-height: 1.5;
    font-weight: 400;
    color: ${props => props.theme === "dark" ? "#ffffffb0" : (props.theme === "medium" ? "#ffffff90" : "#00000090")};
  }
  @media only screen and (max-width: 330px) {
    &>h3 {font-size: 15px;}
    &>p {font-size: 13px;}
  }
  @media only screen and (max-width: ${props => props.columnThreshold1}) {width: 42.5%;}
  @media only screen and (max-width: ${props => props.columnThreshold2}) {
    width: 90%;
    max-width: 400px;
  }
  @media only screen and (min-width: 1441px) {
    margin: 26px 0;
    &>i {font-size: 60px;}
    &>h3 {
      font-size: 20px;
      margin: 16px 0 13px 0;
    }
    &>p {font-size: 16px;}
  }
`;

export const MultiSnippetCollapsible4 = ({ titles = ["LOL ECCE", "PORPOSYCHY", "ECCE HOMO", "ECCE HOMO"], icons = ["fas fa-clock", "fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"], texts = ["sit amet, consectetur adipiscing elit. Donec id finibus tellus, at finibus sem. Morbi eleifend malesuada nunc.", "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada."], color = theme0.colorPrimary, synchronize = true, ...moreProps }) => {
  const [allExpanded, setAllExpanded] = useState(true);
  const toggle = () => setAllExpanded(s => !s);
  return (
    <MSC4Box synchronize={synchronize} {...moreProps}>
      <MSC4Item synchronize={synchronize} allExpanded={allExpanded} allToggle={toggle} key={"msc4-0-" + titles[0]} color={color} icon={icons[0]} title={titles[0]} text={texts[0]} />
      <MSC4Item synchronize={synchronize} allExpanded={allExpanded} allToggle={toggle} key={"msc4-1-" + titles[1]} color={color} icon={icons[1]} title={titles[1]} text={texts[1]} />
      <MSC4Item synchronize={synchronize} allExpanded={allExpanded} allToggle={toggle} key={"msc4-2-" + titles[2]} color={color} icon={icons[2]} title={titles[2]} text={texts[2]} />
      <MSC4Item synchronize={synchronize} allExpanded={allExpanded} allToggle={toggle} key={"msc4-3-" + titles[3]} color={color} icon={icons[3]} title={titles[3]} text={texts[3]} />
    </MSC4Box>
  );
}

const MSC4Item = ({ synchronize, allExpanded, allToggle, key, title, icon, text, color }) => {
  const [expanded, setExpanded] = useState(true);
  const toggle = () => synchronize ? allToggle() : setExpanded(s => !s);
  return (
    <MSC4ItemBox key={key} onClick={toggle} expanded={synchronize ? allExpanded : expanded} color={color}>
      <i color={color} className={((synchronize === true && allExpanded === true) || (synchronize === false && expanded === true)) ? "fas fa-minus collapseIcon4" : "fas fa-plus collapseIcon4"} />
      <i className={icon + " itemIcon4"} /><h3>{title}</h3><p>{text}</p>
    </MSC4ItemBox>
  );
}

const MSC4Box = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: ${props => props.synchronize === true ? "stretch" : "flex-start"};
  margin: calc(12px + 1vw) 0;
  @media only screen and (min-width: 1441px) {margin: 26px 0;}
`;

const MSC4ItemBox = styled.div`
  position: relative;
  height: auto;
  width: 20%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: calc(12px + 1vw) 0;
  box-shadow: 3px 3px 6px #000000b0;
  border-radius: 40px;
  padding: 30px 20px;
  cursor: pointer;
  .collapseIcon4 {
    position: absolute;
    color: ${props => "" + props.color + "90"};
    top: 20px;
    right: 30px;
    font-size: calc(16px + 0.25vw);
  }
  .itemIcon4 {
    font-size: calc(32px + 2vw);
    color: ${props => props.color + "c0"};
  }
  &>h3 {
    font-size: calc(16px + 0.25vw);
    line-height: 1.25;
    font-weight: 600;
    color: #c0c0c0;
    letter-spacing: 1px;
    &::first-letter { color: ${props => "" + props.color + "e8"}; }
    margin: calc(12px + 0.12vw) 0 calc(10px + 0.06vw) 0;
  }
  &>p {
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
    max-height: ${props => props.expanded === false ? "0px" : "200px"};
    font-size: calc(12px + 0.25vw);
    line-height: 1.5;
    font-weight: 400;
    color: #000000a0;
  }
  @media only screen and (max-width: 1100px) {width: 42.5%;}
  @media only screen and (max-width: 700px) {
    width: 90%;
    max-width: 400px;
  }
  @media only screen and (min-width: 1441px) {
    margin: 26px 0;
    .itemIcon4 {font-size: 60px;}
    &>h3 {
      font-size: 20px;
      margin: 16px 0 13px 0;
    }
    &>p {font-size: 16px;}
  }
`;

export const MultiSnippetCollapsible3 = ({ titles = ["LOL ECCE", "PORPOSYCHY", "ECCE HOMO"], icons = ["fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"], texts = ["sit amet, consectetur adipiscing elit. Donec id finibus tellus, at finibus sem. Morbi eleifend malesuada nunc.", "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada."], color = theme0.colorPrimary, synchronize = true, ...moreProps }) => {
  const [allExpanded, setAllExpanded] = useState(true);
  const toggle = () => setAllExpanded(s => !s);
  return (
    <MSC3Box synchronize={synchronize} {...moreProps}>
      <MSC3Item synchronize={synchronize} allExpanded={allExpanded} allToggle={toggle} key={"msc4-0-" + titles[0]} color={color} icon={icons[0]} title={titles[0]} text={texts[0]} />
      <MSC3Item synchronize={synchronize} allExpanded={allExpanded} allToggle={toggle} key={"msc4-1-" + titles[1]} color={color} icon={icons[1]} title={titles[1]} text={texts[1]} />
      <MSC3Item synchronize={synchronize} allExpanded={allExpanded} allToggle={toggle} key={"msc4-2-" + titles[2]} color={color} icon={icons[2]} title={titles[2]} text={texts[2]} />
    </MSC3Box>
  );
}

const MSC3Item = ({ synchronize, allExpanded, allToggle, key, title, icon, text, color }) => {
  const [expanded, setExpanded] = useState(true);
  const toggle = () => synchronize ? allToggle() : setExpanded(s => !s);
  return (
    <MSC3ItemBox key={key} onClick={toggle} expanded={synchronize ? allExpanded : expanded} color={color}>
      <i color={color} className={((synchronize === true && allExpanded === true) || (synchronize === false && expanded === true)) ? "fas fa-minus collapseIcon3" : "fas fa-plus collapseIcon3"} />
      <i className={icon + " itemIcon3"} /><h3>{title}</h3><p>{text}</p>
    </MSC3ItemBox>
  );
}

const MSC3Box = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: ${props => props.synchronize === true ? "stretch" : "flex-start"};
  margin: calc(12px + 1vw) 0;
  @media only screen and (min-width: 1441px) {margin: 26px 0;}
`;

const MSC3ItemBox = styled.div`
  position: relative;
  height: auto;
  width: 25%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: calc(12px + 1vw) 0;
  box-shadow: 3px 3px 6px #000000b0;
  border-radius: 40px;
  padding: 30px 20px;
  cursor: pointer;
  .collapseIcon3 {
    position: absolute;
    color: ${props => "" + props.color + "90"};
    top: 20px;
    right: 30px;
    font-size: calc(16px + 0.25vw);
  }
  .itemIcon3 {
    font-size: calc(32px + 2vw);
    color: ${props => props.color + "c0"};
  }
  &>h3 {
    font-size: calc(16px + 0.25vw);
    line-height: 1.25;
    font-weight: 600;
    color: #c0c0c0;
    letter-spacing: 1px;
    &::first-letter { color: ${props => "" + props.color + "e8"}; }
    margin: calc(12px + 0.12vw) 0 calc(10px + 0.06vw) 0;
  }
  &>p {
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
    max-height: ${props => props.expanded === false ? "0px" : "200px"};
    font-size: calc(12px + 0.25vw);
    line-height: 1.5;
    font-weight: 400;
    color: #000000a0;
  }
  @media only screen and (max-width: 900px) {
    width: 90%;
    max-width: 460px;
  }
  @media only screen and (min-width: 1441px) {
    margin: 26px 0;
    .itemIcon3 {font-size: 60px;}
    &>h3 {
      font-size: 20px;
      margin: 16px 0 13px 0;
    }
    &>p {font-size: 16px;}
  }
`;

export const MultiSnippet3Old = ({ titles = ["LOL ECCE", "PORPOSYCHY", "ECCE HOMO"], icons = ["fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"], texts = ["sit amet, consectetur adipiscing elit. Donec id finibus tellus, at finibus sem. Morbi eleifend malesuada nunc.", "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada."], color = "#30a2ff", ...moreProps }) => {
  return (
    <MS3OldBox {...moreProps}>
      <MS3OldItem color={color}><i className={icons[0]} /><h3>{titles[0]}</h3><p>{texts[0]}</p></MS3OldItem>
      <MS3OldItem color={color}><i className={icons[1]} /><h3>{titles[1]}</h3><p>{texts[1]}</p></MS3OldItem>
      <MS3OldItem color={color}><i className={icons[2]} /><h3>{titles[2]}</h3><p>{texts[2]}</p></MS3OldItem>
    </MS3OldBox>
  );
}

const MS3OldBox = styled.div`
  position: relative;
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: stretch;
  padding: calc(10px + 1vw) 0 calc(10px + 1vw) 0;
`;

const MS3OldItem = styled.div`
  position: relative;
  height: auto;
  width: 30%;
  padding: 1.25%;
  @media only screen and (max-width: 900px) {
    width: 80%;
    max-width: 600px;
    margin-bottom: calc(18px + 0.6vw);
  }
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  &>i {
    font-size: calc(32px + 2vw);
    /* color: #3E56AAd0; */
    color: ${props => props.color + "e0"};
    margin: 0 calc(10px + 1vw);
  }

  &>h3{
    font-size: calc(16px + 0.25vw);
    font-weight: 500;
    /* color: #3E56AA98; */
    color: #909090;
    &::first-letter { color: ${props => props.color}; }
    margin: 8px 0;
  }

  &>p{
    width: 100%;
    font-size: calc(12px + 0.25vw);
    font-weight: 400;
    color: #000000c0;
  }
`;

export const MultiSnippet3NoText = ({ titles = ["LOL ECCE", "PORPOSYCHY", "ECCE HOMO"], icons = ["fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"], color = "#30a2ff", ...moreProps }) => {
  return (
    <MS3OldBoxNT {...moreProps}>
      <MS3OldItemNT color={color}><i className={icons[0]} /><h3>{titles[0]}</h3></MS3OldItemNT>
      <MS3OldItemNT color={color}><i className={icons[1] + " icon-right"} /><h3>{titles[1]}</h3></MS3OldItemNT>
      <MS3OldItemNT color={color}><i className={icons[2]} /><h3>{titles[2]}</h3></MS3OldItemNT>
    </MS3OldBoxNT>
  );
}

const MS3OldBoxNT = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: stretch;
  padding: calc(10px + 1vw) 0 calc(10px + 1vw) 0;
`;

const MS3OldItemNT = styled.div`
  position: relative;
  height: auto;
  width: 30%;
  padding: 0 1.25%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  &>i {
    font-size: calc(32px + 2vw);
    /* color: #3E56AAd0; */
    color: ${props => props.color + "c0"};
    margin: 0 calc(10px + 1vw);
  }

  &>h3{
    font-size: calc(16px + 0.25vw);
    font-weight: 500;
    /* color: #3E56AA98; */
    color: #909090;
    &::first-letter { color: ${props => props.color}; }
    margin: 8px 0;
  }

  &>p{
    width: 100%;
    font-size: calc(12px + 0.25vw);
    font-weight: 400;
    color: #000000c0;
  }

  @media only screen and (max-width: 600px) {
    width: 80%;
    max-width: 500px;
    margin-bottom: calc(8px + 0.2vw);
    flex-flow: row nowrap;
    justify-content: center;
    .icon-right {
      order: 2;
    }
  }
`;
