import React from 'react';
import styled from 'styled-components';
import imgMaintain from 'img/imgs1/followup-types/additives-maintain.jpg';
import imgMonitor from 'img/imgs1/followup-types/additives-monitor.jpg';
import imgOutreach from 'img/imgs1/followup-types/additives-outreach.jpg';
import { Expandable0, Expandable0NoClick } from 'widgets/Expandables';

const FollowUpTypes = () => {
  return (
    <FUTBox>
      {/*<Expandable0 maxHeight="200px" color={"#ffbb33"} theme="medium" align={"left"} src={imgMaintain} title={"MAINTAIN"}>
        <p style={{ margin: "calc(30px + 1vw) calc(40px + 2vw)" }}>Some description about our awesome maintenance process</p>
      </Expandable0>
      <Expandable0 color={"#FFDD99"} theme="medium" align={"right"} src={imgMonitor} title={"MONITOR"}>
        <p style={{ margin: "calc(30px + 1vw) calc(40px + 2vw)" }}>Some description about our awesome monitoring process</p>
      </Expandable0>
      <Expandable0 color={"#ffbb33"} theme="medium" align={"left"} src={imgOutreach} title={"OUTREACH"}>
        <p style={{ margin: "calc(30px + 1vw) calc(40px + 2vw)" }}>Some description about our awesome outreach process</p>
  </Expandable0>*/}
      <Expandable0NoClick maxHeight="200px" color={"#ffbb33"} theme="medium" align={"left"} src={imgMaintain} title={"MAINTAIN"}>
        <p style={{ margin: "calc(30px + 1vw) calc(40px + 2vw)" }}>Some description about our awesome maintenance process</p>
      </Expandable0NoClick>
      <Expandable0NoClick color={"#FFDD99"} theme="medium" align={"right"} src={imgMonitor} title={"MONITOR"}>
        <p style={{ margin: "calc(30px + 1vw) calc(40px + 2vw)" }}>Some description about our awesome monitoring process</p>
      </Expandable0NoClick>
      <Expandable0NoClick color={"#ffbb33"} theme="medium" align={"left"} src={imgOutreach} title={"OUTREACH"}>
        <p style={{ margin: "calc(30px + 1vw) calc(40px + 2vw)" }}>Some description about our awesome outreach process</p>
      </Expandable0NoClick>
    </FUTBox>
  );
}

const FUTBox = styled.div`
  width: 100%;
  margin: calc(30px + 2vw) 0 0 0;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export default FollowUpTypes;
